import Axios from "axios";
import { PACKS_API } from "../config";

function findAll() {
  return Axios.get(PACKS_API).then((response) => response.data["hydra:member"]);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findAll,
};
