import React from "react";
import { FaUserCog, FaUserTag } from "react-icons/fa";
import Header from "./Header";
import NavItem from "./NavItem";

const Manager = () => {
  return (
    <div>
      <Header title="Personal" />
      <NavItem icon={<FaUserTag />} link="Get My Customers" />
      <Header title="Setting" />
      <NavItem icon={<FaUserCog />} link="Personal Information" />
    </div>
  );
};

export default Manager;
