import React, { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import RenderNoState from "../components/customer/RenderNoState";
import RenderState from "../components/customer/RenderState";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import ManagerContext from "../context/ManagerContext";
import CustomerAPI from "../services/CustomerAPI";

const CustomerPage = ({ history, match }) => {
  const { id } = match.params;
  const { manager } = useContext(ManagerContext);
  // On initialise le chargement
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const handleReturn = () => {
    history.goBack();
  };

  const fetchCustomer = async () => {
    try {
      setCustomer(await CustomerAPI.find(id));
      setLoading(false);
    } catch (error) {
      handleReturn();
    }
  };
  const renderActions = () => {
    return customer.manager ? (
      manager["@id"] === customer.manager["@id"] ? (
        customer.financialStatements ? (
          customer.lastReport === "new" ? (
            (customer.lastPurchase && customer.lastPurchase.makeNewReport) ||
            (customer.manager.availableAmount !== null &&
              customer.manager.availableAmount !== 0) ? (
              <NavLink
                to={{
                  pathname: "/simulation/" + customer.lastReport,
                  customerId: customer.id,
                }}
                className="btn btn-primary"
              >
                Start simulation
              </NavLink>
            ) : (
              <NavLink to={"/recharge"} className="btn btn-primary">
                Recharge account
              </NavLink>
            )
          ) : (
            <NavLink
              to={{
                pathname: "/simulation/" + customer.lastReport,
                customerId: customer.id,
              }}
              className="btn btn-primary"
            >
              Recover simulation
            </NavLink>
          )
        ) : (
          <a href={"mailto:" + customer.email}>
            Send a mail to : {customer.firstName + " " + customer.lastName}
          </a>
        )
      ) : (
        <a href={"mailto:" + customer.mangerEmail}>
          Send a mail to : {customer.managerName}
        </a>
      )
    ) : (
      customer.managerName
    );
  };

  useEffect(() => {
    fetchCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return loading ? (
    <LoadingInfo />
  ) : (
    <div>
      <PageHeading title={customer.firstName + " " + customer.lastName}>
        {customer && manager.role !== "Super Administrador" && renderActions()}
      </PageHeading>

      {customer.financialStatements ? (
        <RenderState
          financialStatements={customer.financialStatements}
          properties={customer.properties}
        />
      ) : (
        <RenderNoState />
      )}
      <Container>
        <Button variant="secondary" className="my-3" onClick={handleReturn}>
          Return
        </Button>
      </Container>
    </div>
  );
};

export default CustomerPage;
