import React, { useContext } from "react";
import { Button, Card, Row } from "react-bootstrap";
import StateContext from "../../../context/StateContext";
import { currencyFormat } from "../../../functions/Format";

const RenderSellCeli = ({ sellTfsa, i }) => {
  const { actions, setActions, year } = useContext(StateContext);
  const handleDelete = () => {
    let amount = parseFloat(actions[year].sellTfsa[i]);
    actions[year].sellTfsa.splice(i, 1);
    setActions({
      ...actions,
      [year]: { ...actions[year], sellTfsa: actions[year].sellTfsa },
    });
  };
  return (
    <Card className="mt-1" bg="light" key={i}>
      <Card.Header>Sell Tfsa</Card.Header>
      <Card.Body>
        <Row className="justify-content-around align-items-center">
          {currencyFormat(parseFloat(sellTfsa))}
          <Button variant="primary" size="sm" onClick={handleDelete}>
            Cancel
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RenderSellCeli;
