import React, { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { currencyFormat } from "../../../functions/Format";
import CurrencyFormat from "react-currency-format";
import { useState } from "react";
import StateContext from "../../../context/StateContext";

const Refinance = ({ show, setShow, index, setIndex }) => {
  const { actions, setActions, year } = useContext(StateContext);
  const [refinance, setrefinance] = useState({
    amount: "",
    interest: "",
    amortization: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
    interest: "",
    amortization: "",
  });
  const handleClose = () => {
    setShow(false);
  };

  const handleSubmit = () => {
    // 1. On vérifie que les données aient bien été remplis
    const object = {};
    for (let [key, value] of Object.entries(refinance)) {
      value.trim() === "" && (object[key] = "Required field");
      value < 0 && (object[key] = "Value must be positive");
      if (key)
        key === "amount" &&
          value > index.availableEquity &&
          (object["amount"] = true);
    }

    //Gestion des erreurs
    if (Object.keys(object).length !== 0) {
      setErrors(object);
    } else {
      actions[year].refinance && actions[year].refinance[index.i]
        ? setActions({
            ...actions,
            [year]: {
              ...actions[year],
              refinance: {
                ...actions[year].refinance,
                [index.i]: [...actions[year].refinance[index.i], refinance],
              },
            },
          })
        : setActions({
            ...actions,
            [year]: {
              ...actions[year],
              refinance: { ...actions[year].refinance, [index.i]: [refinance] },
            },
          });
      setrefinance({ amount: "", interest: "", amortization: "" });
      setIndex({});
      setShow(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Refinancing of {index.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <label>Amount *</label>
            <CurrencyFormat
              className="form-control"
              value={refinance.amount}
              id="sellTfsa"
              thousandSeparator={true}
              suffix={" CA$"}
              onValueChange={(values) => {
                const { value } = values;
                setrefinance({
                  ...refinance,
                  amount: value,
                });
                setErrors({ ...errors, amount: "" });
              }}
            />
            {index.availableEquity && (
              <p className={"f-12 " + (errors.amount && "text-danger")}>
                The value must be between {currencyFormat(0)} and{" "}
                {currencyFormat(index.availableEquity)}
              </p>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <label>Interest *</label>
            <CurrencyFormat
              className="form-control"
              value={refinance.interest}
              thousandSeparator={true}
              suffix={" %"}
              onValueChange={(values) => {
                const { value } = values;
                setrefinance({
                  ...refinance,
                  interest: value,
                });
                setErrors({ ...errors, interest: "" });
              }}
            />

            {errors.interest && (
              <p className="f-12 text-danger">{errors.interest}</p>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <label>Amortization *</label>
            <CurrencyFormat
              className="form-control"
              value={refinance.amortization}
              thousandSeparator={true}
              onValueChange={(values) => {
                const { value } = values;

                setrefinance({
                  ...refinance,
                  amortization: value,
                });
                setErrors({ ...errors, amortization: "" });
              }}
            />
            {errors.amortization && (
              <p className="f-12 text-danger">{errors.amortization}</p>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Refinance;
