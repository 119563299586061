import React from "react";
import { Card } from "react-bootstrap";

const RenderNoReports = () => {
  return (
    <div className="container mt-5 mb-2">
      <Card>
        <Card.Body>There is no report to show.</Card.Body>
      </Card>
    </div>
  );
};

export default RenderNoReports;
