import moment from "moment";
import React, { useContext, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { FaPlayCircle } from "react-icons/fa";
import StateContext from "../../context/StateContext";
import Field from "../form/Field";
import PageHeading from "../pageHeading/PageHeading";
import { verifState } from "./functions/VerifState";

const SimulationPlanning = ({ history }) => {
  const { state, setState, setYear, setActions, actions } =
    useContext(StateContext);
  // Initialisation des variables
  const [init, setInit] = useState({
    date: state.date || moment(new Date()).format("YYYY-MM-DD"),
    duration: state.duration || "",
    properties: state[0].properties,
  });
  // Gestion des errors
  const [errors, setErrors] = useState({});

  // Gestion des changements des valeurs date et durée
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setInit({ ...init, [name]: value });
    Reflect.deleteProperty(errors, [name]);
  };

  const handleReturn = () => {
    history.goBack();
  };

  const handleStart = () => {
    if (verifState(init, state[0])) {
      setErrors(verifState(init, state[0]));
    } else {
      // Si première année, on initialise l'année est on crée un payment
      if (!actions[1]) {
        setActions({ ...actions, 1: {} });
      }
      setState({
        id: state.id,
        date: init.date,
        duration: init.duration,
        0: state[0],
      });
      setYear(1);
    }
  };

  // Affichage des propriétées
  const renderProperty = (property, i) => {
    return (
      <Card className="my-3" key={i}>
        <Card.Header>{property.name}</Card.Header>
        <Row className="p-3">
          <Col md={6} className="mb-3">
            <label>Growth rate *</label>
            <CurrencyFormat
              className="form-control"
              value={init.properties[i].growthRate || ""}
              thousandSeparator={true}
              suffix={" %"}
              onValueChange={(values) => {
                const { value } = values;
                const rank = i;
                const arrayProperties = init.properties;
                arrayProperties[rank].growthRate = parseFloat(value);
                setInit({
                  ...init,
                  properties: arrayProperties,
                });
                Reflect.deleteProperty(errors, ["growthRate" + rank]);
              }}
            />
            {errors["growthRate" + i] && (
              <p className="f-12 text-danger">{errors["growthRate" + i]}</p>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <label>Multiplication factor *</label>
            <CurrencyFormat
              className="form-control"
              value={init.properties[i].multiplicationFactor || ""}
              thousandSeparator={true}
              onValueChange={(values) => {
                const { value } = values;
                const rank = i;
                const arrayProperties = init.properties;
                arrayProperties[rank].multiplicationFactor = parseFloat(value);
                setInit({
                  ...init,
                  properties: arrayProperties,
                });
                Reflect.deleteProperty(errors, ["multiplicationFactor" + rank]);
              }}
            />
            {errors["multiplicationFactor" + i] && (
              <p className="f-12 text-danger">
                {errors["multiplicationFactor" + i]}
              </p>
            )}
          </Col>
        </Row>
      </Card>
    );
  };
  return (
    <div>
      <PageHeading title="Simulation planning" />
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Field
                id="date"
                label="Initialization date"
                type="date"
                value={init.date}
                onChange={handleChange}
                error={errors.date}
              />
            </Col>

            <Col md={6}>
              <label className="form-label">
                How many years will this planning be analyzed? *
              </label>
              <CurrencyFormat
                className="form-control"
                value={init.duration}
                thousandSeparator={true}
                suffix={init.duration > 1 ? " years" : " year"}
                onValueChange={(values) => {
                  const { value } = values;
                  setInit({ ...init, duration: value });
                  Reflect.deleteProperty(errors, "duration");
                }}
              />
              {errors.duration && (
                <p className="f-12 text-danger">{errors.duration}</p>
              )}
            </Col>
          </Row>
          {state[0].properties.map(renderProperty)}
          <Button onClick={handleStart}>
            Start
            <FaPlayCircle className="mx-2" />
          </Button>
          <Button onClick={handleReturn} className="mx-3" variant="secondary">
            <Row className="align-items-center m-0">Cancel</Row>
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SimulationPlanning;
