import React from "react";
import { Col, Row } from "react-bootstrap";
import Field from "../form/Field";

const AdministradorInformation = ({
  administrador,
  setAdministrador,
  errors,
  setErrors,
}) => {
  const handleChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setAdministrador({ ...administrador, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  return (
    <div>
      <p>Administrator's information</p>
      <Row>
        <Col md={6}>
          <Field
            id="firstName"
            value={administrador.firstName}
            placeholder="Your first name"
            onChange={handleChange}
            error={errors.firstName}
          />
        </Col>
        <Col md={6}>
          <Field
            id="lastName"
            value={administrador.lastName}
            placeholder="Your name"
            onChange={handleChange}
            error={errors.lastName}
          />
        </Col>
        <Col md={6}>
          <Field
            id="email"
            type="email"
            value={administrador.email}
            placeholder="Email"
            onChange={handleChange}
            error={errors.email}
          />
        </Col>
        <Col md={6}>
          <Field
            id="phone"
            value={administrador.phone}
            placeholder="Phone number"
            onChange={handleChange}
            error={errors.phone}
          />
        </Col>
        <Col md={6}>
          <Field
            id="password"
            type="password"
            value={administrador.password}
            placeholder="Password"
            onChange={handleChange}
            error={errors.password}
          />
        </Col>
        <Col md={6}>
          <Field
            id="confirmPassword"
            type="password"
            value={administrador.confirmPassword}
            placeholder="Confirm password"
            onChange={handleChange}
            error={errors.confirmPassword}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdministradorInformation;
