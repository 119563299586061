import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "../components/card/Card";
import CardBody from "../components/card/CardBody";
import CardHeader from "../components/card/CardHeader";
import LoadingInfo from "../components/loading/LoadingInfo";
import AddManager from "../components/manager/AddManager";
import PageHeading from "../components/pageHeading/PageHeading";
import Table from "../components/table/Table";
import ManagerContext from "../context/ManagerContext";
import CompanyAPI from "../services/CompanyAPI";
import ManagerAPI from "../services/ManagerAPI";

const ManagersPage = () => {
  const [loading, setLoading] = useState(true);
  // On recupère le user connecté
  const { manager: user } = useContext(ManagerContext);
  // On inistialise les managers existants
  const [managers, setManagers] = useState([]);
  // On initialise le modal a faux
  const [show, setShow] = useState(false);
  // Permet de récupérer les managers
  const fetchManagers = async () => {
    try {
      setManagers(await CompanyAPI.companyManagers(user.companyId));
      setLoading(false);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };
  // On gère l'affichage du modal
  const handleShow = () => {
    setShow(true);
  };

  // On gère le suppression d'un manager
  const handleDelete = async (id) => {
    // On initialise les managers existans
    const originalManagers = [...managers];
    // On supprime le manager des managers existant
    setManagers(managers.filter((manager) => manager.id !== id));
    try {
      await ManagerAPI.delete(id);
    } catch (error) {
      setManagers(originalManagers);
    }
  };
  const renderManager = (manager) => {
    return (
      <tr key={manager.email}>
        <td>
          {manager.firstName} {manager.lastName}
        </td>
        <td>
          <a href={"mailto:" + manager.email}>{manager.email}</a>
        </td>
        <td>{manager.phone}</td>
        <td className="d-flex justify-content-center">
          <Button
            className="btn btn-change btn-danger"
            onClick={() => handleDelete(manager.id)}
            disabled={user["@id"] === manager["@id"]}
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PageHeading
        title={user.role === "Super Administrador" ? "Super Admin" : "Managers"}
      >
        <Button variant="primary" onClick={handleShow}>
          {user.role === "Super Administrador"
            ? "Add a super admin"
            : "Add a manager"}
        </Button>
      </PageHeading>
      <Card>
        <CardHeader
          title={
            user.role === "Super Administrador"
              ? "Super Admin list"
              : "Managers list"
          }
        />
        <CardBody>
          {loading ? (
            <div className="relative pb-5">
              <LoadingInfo />
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {managers.length > 0 ? (
                  managers.map(renderManager)
                ) : (
                  <tr>
                    <td colSpan="4">Your manager list is empty</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      <AddManager
        show={show}
        setShow={setShow}
        managers={managers}
        setManagers={setManagers}
        company={user.companyName}
      />
    </div>
  );
};

export default ManagersPage;
