import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { currencyFormat } from "../../functions/Format";

const RenderState = ({ state }) => {
  return (
    <Card>
      <Card.Header>Your portfolio</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p className="m-0">Initial investment amount:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.investedAmount)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">Portfolio liquidity:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.portfolioLiquidity)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">Total equity:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.availableEquities)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">No RRSP:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.noReer)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">Operating Expense:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.operatingExpenses)}
                </p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p className="m-0">Income:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.propertyIncome)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">TFSA:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.celi)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">RRSP:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.reer)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">Enterprise Value:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.companyValue)}
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p className="m-0">Market values:</p>
                <p className="font-weight-bold m-0">
                  {currencyFormat(state.marketValues)}
                </p>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RenderState;
