import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import PageHeading from "../components/pageHeading/PageHeading";
import ManagerContext from "../context/ManagerContext";
import CompanyAPI from "../services/CompanyAPI";
import CustomerAPI from "../services/CustomerAPI";

const ManageCustomerByAdminPage = ({ match, history }) => {
  // On récupère le nom passé en url
  const { id } = match.params;
  // On recupère le user connecté
  const { manager: user } = useContext(ManagerContext);
  // On récupère l'id passé dans le state
  const [loading, setLoading] = useState(false);
  const { state } = history.location;
  const [assign, setAssign] = useState(state && state.isChecked);
  const [managers, setManagers] = useState([]);

  const fetchCompanies = async () => {
    try {
      setManagers(await CompanyAPI.companyManagers(user.companyId));
      setLoading(false);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };
  const handleChange = (id) => {
    setAssign(id);
  };

  const handleReturn = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await CustomerAPI.assignManager(state, assign);
      handleReturn();
    } catch (error) {
      setLoading(false);
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  useEffect(() => {
    state ? fetchCompanies() : handleReturn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, state]);

  return (
    <div>
      <PageHeading title={"Choose a manager for " + id}></PageHeading>
      <Container>
        <div className="mb-3">
          <Form.Check
            inline
            label={"None"}
            name="managers"
            type="radio"
            id={null}
            checked={assign === null}
            onChange={() => handleChange(null)}
          />
        </div>
        {managers.map((manager) => (
          <div key={manager["@id"]} className="mb-3">
            <Form.Check
              inline
              label={
                manager.email +
                " - (" +
                manager.firstName +
                " " +
                manager.lastName +
                ")"
              }
              name="managers"
              type="radio"
              id={manager["@id"]}
              checked={assign === manager.id}
              onChange={() => handleChange(manager.id)}
            />
          </div>
        ))}

        <Button
          onClick={handleSubmit}
          className="mr-1"
          variant="primary"
          disabled={loading}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "5px", marginBottom: "2px" }}
              size="sm"
              animation="border"
            />
          )}
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleReturn}>
          Cancel
        </Button>
      </Container>
    </div>
  );
};

export default ManageCustomerByAdminPage;
