import React, { useContext, useEffect, useState } from "react";
import { Tab, Table, Tabs } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Card from "../components/card/Card";
import CardBody from "../components/card/CardBody";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import ManagerContext from "../context/ManagerContext";
import { currencyFormat, dateFormat } from "../functions/Format";
import CompanyAPI from "../services/CompanyAPI";

const HistoryPage = () => {
  const { manager } = useContext(ManagerContext);
  const [loading, setLoading] = useState(true);

  const [wallets, setWallets] = useState({});

  const fetchHistories = async () => {
    try {
      setWallets(await CompanyAPI.findCompanyTransactions(manager.companyId));
      setLoading(false);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  useEffect(() => {
    fetchHistories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PageHeading title="History">
        <NavLink to={"/recharge"} className="btn btn-primary">
          Recharge account
        </NavLink>
      </PageHeading>
      <Card>
        <CardBody>
          {loading ? (
            <div className="relative pb-5">
              <LoadingInfo />
            </div>
          ) : (
            <Tabs defaultActiveKey="debit" id="history" className="mb-3">
              <Tab eventKey="credit" title="Purchase">
                {wallets.transactions.length > 0 ? (
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Old Credit</th>
                        <th>Amount</th>
                        <th>New Credit</th>
                        <th>Ref</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wallets.transactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td>{dateFormat(transaction.createdAt)}</td>
                          <td>{transaction.oldCredit}</td>
                          <td>{currencyFormat(transaction.amount / 100)}</td>
                          <td>{transaction.newCredit}</td>
                          <td>{transaction.ref}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>There is no transaction history</p>
                )}
              </Tab>
              <Tab eventKey="debit" title="Credit left">
                {wallets.purchases.length > 0 ? (
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Old Credit</th>
                        <th>By</th>
                        <th>For</th>
                        <th>New Credit</th>
                        <th>Report Left</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wallets.purchases.map((purchase) => (
                        <tr key={purchase.id}>
                          <td>{dateFormat(purchase.createdAt)}</td>
                          <td>{purchase.oldCredit}</td>
                          <td>
                            {purchase.manager.email}{" "}
                            <span className="small">
                              - {purchase.manager.firstName}{" "}
                              {purchase.manager.lastName}
                            </span>
                          </td>
                          <td>
                            {purchase.customer.email}{" "}
                            <span className="small">
                              - {purchase.customer.firstName}{" "}
                              {purchase.customer.lastName}
                            </span>
                          </td>
                          <td>{purchase.newCredit}</td>
                          <td>{3 - purchase.report}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>There is no transaction history</p>
                )}
              </Tab>
            </Tabs>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default HistoryPage;
