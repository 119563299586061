import React from "react";
import { Accordion, Card, Col, ListGroup, Row } from "react-bootstrap";
import { currencyFormat, dateFormat } from "../../functions/Format";

const RenderProperties = ({ properties }) => {
  const renderProperty = (property, i) => {
    return (
      <Card key={i} className="mt-3">
        <Accordion.Item eventKey={i.toString()}>
          <Accordion.Header>
            <div>Property name: {property.name}</div>
            <div className="f-12 px-5">
              {property.editedAt
                ? " edited on " + dateFormat(property.createdAt)
                : " added on " + dateFormat(property.createdAt)}
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ListGroup>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <p className="m-0">Purchasing price:</p>
                    <p className="m-0">
                      {currencyFormat(property.marketValue)}
                    </p>
                  </Col>
                  <Col>
                    <p className="m-0">Mortgage:</p>
                    <p className="m-0">{currencyFormat(property.mortgage)}</p>
                  </Col>
                  <Col>
                    <p className="m-0">Interest rate:</p>
                    <p className="m-0">{property.interest} %</p>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <p className="m-0">Amortization:</p>
                    <p className="m-0">
                      {property.amortization} year
                      {property.amortization > 1 && "s"}
                    </p>
                  </Col>
                  <Col>
                    <p className="m-0">Income:</p>
                    <p className="m-0">{currencyFormat(property.income)}</p>
                  </Col>
                  <Col>
                    <p className="m-0">Expenses:</p>
                    <p className="m-0">
                      {currencyFormat(property.operatingExpense)}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    );
  };
  return (
    <Accordion defaultActiveKey="0">{properties.map(renderProperty)}</Accordion>
  );
};

export default RenderProperties;
