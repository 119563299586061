import Axios from "axios";
import { API_URL, REPORTS_API, URL } from "../config";

function create(id) {
  return Axios.post(REPORTS_API + "/" + id, {}).then(
    (response) => response.data
  );
}

function find(id) {
  return Axios.get(REPORTS_API + "/" + id).then((response) => response.data);
}

function save(id, datas) {
  return Axios.put(REPORTS_API + "/" + id, { datas });
}

function complete(id, datas) {
  return Axios.put(REPORTS_API + "/complete/" + id, { datas });
}

function deleteReport(id) {
  return Axios.delete(REPORTS_API + "/" + id);
}

function download(id) {
  window.location.href = API_URL + "aino/download/" + id;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create,
  find,
  save,
  complete,
  delete: deleteReport,
  download,
};
