import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({
  icon,
  link,
  href = "/" + link.toLowerCase().replace(/ /g, "-"),
}) => {
  return (
    <li className="nav-item">
      <NavLink className="nav-link" to={href}>
        {icon}
        <span>{link}</span>
      </NavLink>
    </li>
  );
};

export default NavItem;
