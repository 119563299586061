import React, { useContext } from "react";
import { convert } from "../../functions/Convert";
import { useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import StateContext from "../../context/StateContext";
import { currencyFormat } from "../../functions/Format";
import Refinance from "./actions/Refinance";

const RenderProperties = ({ properties }) => {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState({});

  const handleShow = (i, name, availableEquity) => {
    setShow(true);
    setIndex({ i, name, availableEquity });
  };

  const { actions, setActions, year, state } = useContext(StateContext);
  const renderProperty = (property, i) => {
    const handleCancel = () => {
      let position = i - properties.length + actions[year].addProperty.length;
      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(actions)) {
        let refinance = value.refinance;
        if (refinance) {
          // eslint-disable-next-line no-unused-vars
          for (let [key, value] of Object.entries(refinance)) {
            // eslint-disable-next-line eqeqeq
            key == i && Reflect.deleteProperty(refinance, [key]);
          }
        }
      }
      actions[year].addProperty.splice(position, 1);
      setActions({
        ...actions,
        [year]: { ...actions[year], sellTfsa: actions[year].sellTfsa },
      });
    };
    const renderRefinance = (refinance, y) => {
      const handleDelete = () => {
        let refinances = actions[year].refinance[i].splice(y, 1);
        setActions({ ...actions, [year]: { ...actions[year], i: refinances } });
      };
      return (
        <tr key={i} className="f-12">
          <td colSpan="5" className="text-right font-weight-bold p-1">
            {convert(y + 1)}
          </td>
          <td className="p-1">
            {currencyFormat(parseFloat(refinance.amount))}
          </td>
          <td className="p-1">{refinance.interest} %</td>
          <td className="p-1">
            {refinance.amortization} year{refinance.amortization > 1 && "s"}
          </td>
          <td className="p-1">
            <Button
              size="sm"
              variant="danger"
              className="w-100 f-10"
              onClick={handleDelete}
              disabled={state[year].availableEquities === 0}
            >
              Cancel
            </Button>
          </td>
        </tr>
      );
    };

    return (
      <tbody key={i}>
        <tr className="f-12">
          <td>{property.name}</td>
          <td>{currencyFormat(property.marketValue)}</td>
          <td>{currencyFormat(property.marketValue2)}</td>
          <td>{currencyFormat(property.netValue)}</td>
          <td>{currencyFormat(property.availableEquity)}</td>
          <td>{currencyFormat(property.income)}</td>
          <td>{currencyFormat(property.operatingExpense)}</td>
          <td>{currencyFormat(property.annualLiquidity)}</td>
          <td className="text-center f-12">
            <>
              <Button
                size="sm"
                variant="success"
                className="w-100"
                onClick={() =>
                  handleShow(i, property.name, state[year].availableEquities)
                }
                disabled={state[year].availableEquities === 0}
              >
                Refinance
              </Button>
            </>

            {property.isAquisition && (
              <>
                <Button
                  size="sm"
                  variant="danger"
                  className="mt-1 w-100"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
          </td>
        </tr>
        {actions[year].refinance &&
          actions[year].refinance[i] &&
          actions[year].refinance[i].map(renderRefinance)}
      </tbody>
    );
  };

  return properties.length > 0 ? (
    <Card className="mt-3">
      <Card.Header>Your properties</Card.Header>
      <Card.Body className="px-0">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Purchasing Price</th>
              <th>Market Value</th>
              <th>Net Value</th>
              <th>Available Equity</th>
              <th>Income</th>
              <th>Operating Expense</th>
              <th>Annual Liquidity</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          {properties.map(renderProperty)}
        </Table>
      </Card.Body>
      <Refinance
        show={show}
        setShow={setShow}
        index={index}
        setIndex={setIndex}
      />
    </Card>
  ) : (
    <p>There is no real estate associated with your financial condition.</p>
  );
};

export default RenderProperties;
