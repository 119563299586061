import React, { useContext } from "react";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import ManagerContext from "../../context/ManagerContext";
import AuthAPI from "../../services/AuthAPI";

const Topbar = ({ history }) => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const { manager } = useContext(ManagerContext);

  const handleLogout = () => {
    AuthAPI.logout();
    setIsAuthenticated(false);
    history.replace("/");
    toast.success("You have been disconnected with success !", {
      autoClose: 1000,
    });
  };

  return (
    <nav className="navbar navbar-light bg-white topbar mb-4 p-3 static-top shadow justify-content-between align-items-center">
      <div>Hi {manager.firstName}</div>
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={handleLogout}
      >
        Logout
      </button>
    </nav>
  );
};

export default Topbar;
