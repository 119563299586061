import Axios from "axios";
import { RELOAD_REQUEST_API } from "../config";

function edit(id, manager) {
  return Axios.put(RELOAD_REQUEST_API + "/" + id, {
    managers: manager,
  }).then((response) => response.data["@id"]);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  edit,
};
