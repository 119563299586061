import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { currencyFormat, dateFormat } from "../../functions/Format";
import RenderProperties from "./RenderProperties";

const RenderState = ({ financialStatements, properties }) => {
  return (
    <div className="container mt-5 mb-2">
      <Card>
        <Card.Header>
          Financial statement completed on{" "}
          {dateFormat(
            financialStatements.createdAt
              ? financialStatements.createdAt
              : financialStatements.editedAt
          )}
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Initial investment amount:{" "}
                    {currencyFormat(financialStatements.investedAmount)}
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Income: {currencyFormat(financialStatements.income)}
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    Other Incomes:{" "}
                    {currencyFormat(financialStatements.otherIncome)}
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Debt: {currencyFormat(financialStatements.debt)}
                  </p>
                  <p className="m-0">
                    Interest rate: {financialStatements.interest || 0} %
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    TFSA: {currencyFormat(financialStatements.celi)}
                  </p>
                  <p className="m-0">
                    Rate: {financialStatements.celiP || 0} %
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    RRSP: {currencyFormat(financialStatements.reer)}
                  </p>
                  <p className="m-0">
                    Rate: {financialStatements.reerP || 0} %
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    No RRSP: {currencyFormat(financialStatements.noReer)}
                  </p>
                  <p className="m-0">
                    No Reer Performance: {financialStatements.noReerP || 0} %
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <p className="m-0">
                    Enterprise Value:{" "}
                    {currencyFormat(financialStatements.companyValue)}
                  </p>
                  <p className="m-0">
                    Growth rate: {financialStatements.companyValueP || 0} %
                  </p>
                </Col>
                <Col>
                  <p className="m-0">
                    Management company:{" "}
                    {currencyFormat(financialStatements.managementValue)}
                  </p>
                  <p className="m-0">
                    Growth rate: {financialStatements.managementValueP || 0} %
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>

      {properties.length > 0 ? (
        <div>
          <RenderProperties properties={properties} />
        </div>
      ) : (
        <Card.Text className="mt-3">
          The customer has no properties associated with his financial
          statement.
        </Card.Text>
      )}
    </div>
  );
};

export default RenderState;
