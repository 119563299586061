import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import StateContext from "../../../context/StateContext";
import { currencyFormat } from "../../../functions/Format";

const SellCeli = ({ show, setShow }) => {
  const { state, year, actions, setActions } = useContext(StateContext);

  const [amount, setAmount] = useState("");
  // Gestion des erreurs
  const [error, setError] = useState("");
  // Gestion du changement de montant
  const handleChange = (values) => {
    const { value } = values;
    setAmount(value);
    setError(false);
  };
  const handleSubmit = () => {
    // On vérfifie que le montant est inférieur ou égalie au celi disponible
    if (amount && amount <= state[year].celi) {
      actions[year].sellTfsa
        ? setActions({
            ...actions,
            [year]: {
              ...actions[year],
              sellTfsa: [...actions[year].sellTfsa, amount],
            },
          })
        : setActions({
            ...actions,
            [year]: {
              ...actions[year],
              sellTfsa: [amount],
            },
          });
      setShow(false);
    } else {
      setError(true);
    }
  };
  return (
    <Modal show={show} onHide={() => setShow("")}>
      <Modal.Header closeButton>
        <Modal.Title>Sell Tfsa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Amount *</label>
        <CurrencyFormat
          onValueChange={handleChange}
          className="form-control"
          value={amount}
          id="sellTfsa"
          thousandSeparator={true}
          suffix={" CA$"}
        />
        <p className={"f-12 " + (error && "text-danger")}>
          The value must be between {currencyFormat(0)} and{" "}
          {currencyFormat(state[year].celi)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow("")}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SellCeli;
