import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import PageHeading from "../components/pageHeading/PageHeading";
import CheckoutForm from "../components/stripe/CheckoutForm";
import PackAPI from "../services/PackAPI";

const RechargeByAdmin = ({ history }) => {
  const [packs, setPacks] = useState([]);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(null);

  const handleClose = () => {
    setItem(null);
    setShow(false);
  };
  const handleShow = (id) => {
    setItem(id);
    setShow(true);
  };

  const handleReturn = () => {
    history.goBack();
  };

  const stripe = loadStripe(
    "pk_test_51JYpllDmPtXVGOY3PJVkPIeiP4nQJqR1wbHCjAoapvGq434GzB4NPuXsn0qdeY8q4Us5Duihm9Q9PNSdswtPj7XQ00m6OsbZqB"
  );

  const renderPack = (pack) => {
    return (
      <div className="col-md-5 col-lg-4" key={pack.id}>
        <div className="item">
          {pack.bestValue && <div className="ribbon">Best Value</div>}
          <div className="heading">
            <h3>{pack.name}</h3>
          </div>
          <p>{pack.text}.</p>
          <div className="features">
            <h4>
              <span className="feature">
                {pack.credit} package{pack.credit > 1 && "s"}
              </span>
            </h4>
          </div>
          <div className="price">
            <h4>{pack.price} CA$</h4>
          </div>
          <button
            className="btn btn-block btn-outline-primary"
            onClick={() => handleShow(pack)}
          >
            BUY NOW
          </button>
        </div>
      </div>
    );
  };

  const fetchPacks = async () => {
    try {
      setPacks(await PackAPI.findAll());
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  useEffect(() => {
    fetchPacks();
  }, []);

  return (
    <div>
      <PageHeading title="Recharge your account" />
      <Container>
        <div className="pricing-table">
          <Row>{packs.map(renderPack)}</Row>
        </div>
        <Button variant="secondary" className="my-3" onClick={handleReturn}>
          Return
        </Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <Elements stripe={stripe}>
              {item && <CheckoutForm item={item} history={history} />}
            </Elements>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default RechargeByAdmin;
