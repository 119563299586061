import React, { useContext } from "react";
import { FaTachometerAlt } from "react-icons/fa";
import ManagerContext from "../../context/ManagerContext";
import Admin from "./Admin";
import Brand from "./Brand";
import Divider from "./Divider";
import Manager from "./Manager";
import NavItem from "./NavItem";
import SuperAdmin from "./SuperAdmin";

const SideBar = () => {
  const { manager } = useContext(ManagerContext);
  const renderSideBar = () => {
    switch (manager.role) {
      case "Administrador":
        return <Admin />;
      case "Super Administrador":
        return <SuperAdmin />;
      default:
        return <Manager />;
    }
  };

  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <Brand />
      <Divider />
      <NavItem icon={<FaTachometerAlt />} link="Dashboard" href="/" />
      {renderSideBar()}
    </ul>
  );
};

export default SideBar;
