import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./components/loading/Loading";
import SideBar from "./components/sidebar/SideBar";
import Topbar from "./components/topbar/Topbar";
import AuthContext from "./context/AuthContext";
import ManagerContext from "./context/ManagerContext";
import AllCompaniesPage from "./pages/AllCompaniesPage";
import AllCustomersPage from "./pages/AllCustomersPage";
import CustomerPage from "./pages/CustomerPage";
import CustomersPage from "./pages/CustomersPage";
import DashboardPage from "./pages/DashboardPage";
import HistoryPage from "./pages/HitsoryPage";
import ManageCompanyPage from "./pages/ManageCompanyPage";
import ManageCustomerByAdminPage from "./pages/ManageCustomerByAdminPage";
import ManageCustomerPage from "./pages/ManageCustomerPage";
import ManagersPage from "./pages/ManagersPage";
import PersonalPage from "./pages/PersonalPage";
import RechargeByAdmin from "./pages/RechargeByAdmin";
import RechargeByManager from "./pages/RechargeByManager";
import ReportsPage from "./pages/ReportsPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import SimulationPage from "./pages/SimulationPage";
import ViewInformationPage from "./pages/ViewInformationPage";
import ViewReportPage from "./pages/ViewReportPage";
import "./scss/styles.scss";
import AuthAPI from "./services/AuthAPI";

AuthAPI.setup();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthAPI.isAuthenticated()
  );

  const [loading, setLoading] = useState(true);

  const [manager, setManager] = useState(null);

  const fetchManager = async () => {
    try {
      setManager(await AuthAPI.currentManager());
      setLoading(false);
    } catch (error) {
      setLoading(true);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    isAuthenticated && fetchManager();
  }, [isAuthenticated]);

  const SidebarWithRouter = withRouter(SideBar);
  const TopbarWithRouter = withRouter(Topbar);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <HashRouter>
        {!isAuthenticated ? (
          <Switch>
            <Route path="/sign-up" component={SignUpPage} />
            <Route path="/" component={SignInPage} />
          </Switch>
        ) : (
          <ManagerContext.Provider value={{ manager, setManager }}>
            {loading ? (
              <Loading />
            ) : (
              <div id="wrapper">
                <SidebarWithRouter />
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    <TopbarWithRouter />
                    <Switch>
                      {manager.role === "Super Administrador" && (
                        <>
                          <Route
                            path="/personal-information"
                            component={PersonalPage}
                          />
                          <Route
                            path="/view-information/:id"
                            component={ViewInformationPage}
                          />
                          <Route
                            path="/companies"
                            component={AllCompaniesPage}
                          />
                          <Route path="/managers" component={ManagersPage} />
                          <Route
                            path="/customers/:id"
                            component={CustomerPage}
                          />
                          <Route
                            exact
                            path="/customers"
                            component={AllCustomersPage}
                          />
                          <Route
                            path="/choose-an-administrador/:id"
                            component={ManageCompanyPage}
                          />
                          <Route
                            exact
                            path="/choose-a-company/:id"
                            component={ManageCustomerPage}
                          />
                          <Route exact path="/" component={DashboardPage} />
                        </>
                      )}
                      {manager.role === "Administrador" && (
                        <>
                          <Route
                            path="/personal-information"
                            component={PersonalPage}
                          />
                          <Route path="/managers" component={ManagersPage} />
                          <Route
                            path="/customers/:id"
                            component={CustomerPage}
                          />
                          <Route
                            exact
                            path="/get-my-customers"
                            component={CustomersPage}
                          />
                          <Route
                            exact
                            path="/customers"
                            component={CustomersPage}
                          />
                          <Route
                            path="/view-report"
                            component={ViewReportPage}
                          />
                          <Route path="/recharge" component={RechargeByAdmin} />
                          <Route
                            path="/simulation/:id"
                            component={SimulationPage}
                          />
                          <Route path="/history" component={HistoryPage} />
                          <Route path="/reports/:id" component={ReportsPage} />
                          <Route
                            path="/save-as-pdf/:id"
                            component={ReportsPage}
                          />
                          <Route
                            path="/choose-a-manager/:id"
                            component={ManageCustomerByAdminPage}
                          />
                          <Route exact path="/" component={DashboardPage} />
                        </>
                      )}
                      {manager.role === "Manager" && (
                        <>
                          <Route
                            path="/personal-information"
                            component={PersonalPage}
                          />
                          <Route
                            path="/customers/:id"
                            component={CustomerPage}
                          />
                          <Route
                            exact
                            path="/get-my-customers"
                            component={CustomersPage}
                          />
                          <Route path="/reports/:id" component={ReportsPage} />
                          <Route
                            path="/view-report"
                            component={ViewReportPage}
                          />
                          <Route
                            path="/recharge"
                            component={RechargeByManager}
                          />
                          <Route
                            path="/simulation/:id"
                            component={SimulationPage}
                          />
                          <Route exact path="/" component={DashboardPage} />
                        </>
                      )}
                    </Switch>
                  </div>
                </div>
              </div>
            )}
          </ManagerContext.Provider>
        )}
        <ToastContainer />
      </HashRouter>
    </AuthContext.Provider>
  );
}

export default App;
