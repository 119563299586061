import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Divider from "../components/Divider";
import AdministradorInformation from "../components/sign/AdministradorInformation";
import CompanyInformation from "../components/sign/CompanyInformation";
import Title from "../components/sign/Title";
import Mailer from "../services/Mailer";
import ManagerAPI from "../services/ManagerAPI";

const SignUpPage = ({ history }) => {
  const [administrador, setAdministrador] = useState({
    password: "",
    confirmPassword: "",
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    company: {
      name: "",
      address: "",
      zipCode: "",
      city: "",
      province: "",
      country: "",
    },
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({ company: {} });

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const apiErrors = { company: {} };
    try {
      await ManagerAPI.create(administrador);
      toast.success("You are successfully registered, you can now login !", {
        autoClose: 1000,
      });
      Mailer.sendMail("company_registration", {
        email: administrador.email,
        username: administrador.firstName + " " + administrador.lastName,
        company: administrador.company.name,
      });
      history.replace("/sign-in");
    } catch (error) {
      window.scrollTo(0, 0);
      toast.warning(" Please check the information provided !", {
        autoClose: 1000,
      });
      setLoading(false);
      const { violations } = error.response.data;
      if (violations) {
        violations.forEach((violation) => {
          violation.propertyPath.split(".")[0] === "company"
            ? (apiErrors.company[violation.propertyPath.split(".")[1]] =
                violation.message)
            : (apiErrors[violation.propertyPath] = violation.message);
        });
        setErrors(apiErrors);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gradient-primary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-5">
                <Title title="Sign Up" />
                <Divider />
                <Form className="user" onSubmit={handleSubmit}>
                  <CompanyInformation
                    administrador={administrador}
                    setAdministrador={setAdministrador}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <Divider />
                  <AdministradorInformation
                    administrador={administrador}
                    setAdministrador={setAdministrador}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <Button
                    className="btn-user btn-block"
                    variant="primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        style={{
                          marginRight: "5px",
                          marginBottom: "2px",
                        }}
                        size="sm"
                        animation="border"
                      />
                    )}
                    Sign Up
                  </Button>
                </Form>
                <Divider />
                <div className="text-center">
                  <NavLink className="small" to="">
                    Forgot Password?
                  </NavLink>
                </div>
                <div className="text-center">
                  <NavLink className="small" to="/sign-in">
                    Already have an account? Login!
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
