import React, { useState } from "react";
import { NavLink } from "react-router-dom";
const RenderCustomer = ({ data, role, pathname }) => {
  const [customer] = useState(data);
  return (
    <tr key={customer["@id"]}>
      <td>
        {customer.firstName} {customer.lastName}
      </td>
      <td>{customer.email}</td>
      {pathname === "/customers" && <td>{customer.managerName}</td>}
      <td className="text-center">
        {customer.numberOfReports > 0 ? (
          <NavLink to={"/reports/" + customer.id}>
            {customer.numberOfReports}
          </NavLink>
        ) : (
          customer.numberOfReports
        )}
      </td>
      <td className="text-center">
        <NavLink
          to={"/customers/" + customer.id}
          className="btn btn-change btn-primary"
        >
          View
        </NavLink>
        {role === "Administrador" && (
          <NavLink
            to={{
              pathname:
                "/choose-a-manager/" +
                customer.firstName +
                "-" +
                customer.lastName,
              state: {
                id: customer.id,
                isChecked: customer.managerId,
              },
            }}
            className="btn btn-change btn-success"
          >
            Manage
          </NavLink>
        )}
      </td>
    </tr>
  );
};

export default RenderCustomer;
