import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { URL } from "../../config";
import ManagerContext from "../../context/ManagerContext";
import ReloadRequestAPI from "../../services/ReloadRequestAPI";
import TransactionAPI from "../../services/TransactionAPI";

export default function CheckoutForm({ item, history }) {
  const { manager, setManager } = useContext(ManagerContext);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch(URL + "/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: item.id }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
      });
  }, [item]);

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#303238",
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: manager.email,
        },
      },
    });

    if (payload.error) {
      setError(`Payment failed => ${payload.error.message}`);
      setProcessing(false);
    } else {
      manager.datas[2].value += item.credit;
      await TransactionAPI.create({
        ref: payload.paymentIntent.id,
        action: payload.paymentIntent.object,
        amount: payload.paymentIntent.amount,
      });
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      const response = await ReloadRequestAPI.edit(
        manager.reloadRequestId.id,
        []
      );
      setManager({
        ...manager,
        ["reloadRequestId"]: { id: manager.reloadRequestId.id, managers: [] },
      });
      history.push("/");
      toast.success("Your credit has been topped up !", { autoClose: 1000 });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <p>You are about to pay {item.price} CA$</p>
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <button
        className="btn btn-primary mt-3"
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        {processing ? <Spinner animation="border" role="status" /> : "Pay now"}
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
    </form>
  );
}
