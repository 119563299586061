import moment from "moment";
import CurrencyFormat from "react-currency-format";

// Formatage des monnaies
export const currencyFormat = (value) => {
  return (
    <CurrencyFormat
      value={value !== null ? value.toFixed(2) : 0}
      displayType={"text"}
      thousandSeparator={true}
      suffix={" CA$"}
    />
  );
};

// Formatage de la date
export const dateFormat = (value) => {
  return moment(value).format("MMM Do YY");
};

// Valeurs arrondies
export const roundingValue = (value) => {
  return Math.round(value * 100) / 100;
};
