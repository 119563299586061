import React from "react";
import { useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CompanyAPI from "../../services/CompanyAPI";

const RenderCompaniesForSuperAdmin = ({ companies, setCompanies, data }) => {
  const company = data;
  const [confirm, setConfirm] = useState(false);

  const handleConfirm = () => {
    setConfirm(!confirm);
  };

  const handleDelete = async () => {
    const originalCompanies = [...companies];
    setCompanies(companies.filter((value) => value["@id"] !== company["@id"]));
    try {
      await CompanyAPI.delete(data.id);
    } catch (error) {
      setCompanies(originalCompanies);
    }
  };
  return (
    <tr key={company["@id"]}>
      <td>{company.name}</td>
      <td>
        {company.administrador ? company.administrador : "No Administrador"}
      </td>
      <td className="text-center">
        <NavLink
          to={{
            pathname: "/view-information/" + company.name,
            state: {
              id: company.id,
            },
          }}
          className="btn btn-change btn-primary"
        >
          View
        </NavLink>
        <NavLink
          to={{
            pathname: "/choose-an-administrador/" + company.name,
            state: {
              id: company.id,
              isChecked: company.administradorId,
            },
          }}
          className="btn btn-change btn-success"
        >
          Manage
        </NavLink>
        <Button onClick={handleConfirm} className="btn-change" variant="danger">
          {confirm ? "Cancel" : "Delete"}
        </Button>

        {confirm && (
          <div className="toast-confirm">
            <Toast show={confirm} className="d-inline-block m-1">
              <Toast.Body className={"text-danger f-12"}>
                By deleting the company, you will automatically delete its
                associated managers.
              </Toast.Body>
              <Button className="btn-change mb-1" onClick={handleDelete}>
                Confirm
              </Button>
            </Toast>
          </div>
        )}
      </td>
    </tr>
  );
};

export default RenderCompaniesForSuperAdmin;
