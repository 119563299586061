import Axios from "axios";
import { COMPANIES_API } from "../config";

function findAll() {
  return Axios.get(COMPANIES_API).then(
    (response) => response.data["hydra:member"]
  );
}

function deleteCompany(id) {
  return Axios.delete(COMPANIES_API + "/" + id);
}

function companyManagers(id) {
  return Axios.get(COMPANIES_API + "/" + id + "/managers").then(
    (response) => response.data["hydra:member"]
  );
}

function companyCustomers(id) {
  return Axios.get(COMPANIES_API + "/" + id + "/customers").then(
    (response) => response.data["hydra:member"]
  );
}

function findCompanyTransactions(id) {
  return Axios.get(COMPANIES_API + "/" + id + "/wallets").then(
    (response) => response.data
  );
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findAll,
  delete: deleteCompany,
  companyManagers,
  companyCustomers,
  findCompanyTransactions,
};
