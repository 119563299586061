import React from "react";
import Divider from "./Divider";

const Header = ({ title }) => {
  return (
    <div>
      <Divider />
      <div className="sidebar-heading">{title}</div>
    </div>
  );
};

export default Header;
