// export const URL = "https://127.0.0.1:8000"; // Dev
export const URL = "https://bino.inomnem.io"; // Prod

export const API_URL = URL + "/api/";

export const ADMIN_URL = API_URL + "aino";
export const COMPANIES_API = API_URL + "aino/companies";
export const CUSTOMERS_API = API_URL + "aino/customers";
export const MANAGERS_API = API_URL + "aino/managers";
export const REPORTS_API = API_URL + "aino/reports";
export const PACKS_API = API_URL + "aino/packs";
export const RELOAD_REQUEST_API = API_URL + "aino/reload_request";
export const TRANSACTIONS_API = API_URL + "aino/transactions";
export const CURRENT_MANAGER = API_URL + "aino/current_manager";
