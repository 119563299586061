import React from "react";
import {
  FaHistory,
  FaUserCog,
  FaUserFriends,
  FaUsers,
  FaUserTag,
} from "react-icons/fa";
import Header from "./Header";
import NavItem from "./NavItem";

const Admin = () => {
  return (
    <div>
      <Header title="Company" />
      <NavItem icon={<FaUserFriends />} link="Managers" />
      <NavItem icon={<FaUsers />} link="Customers" />
      <NavItem icon={<FaHistory />} link="History" />
      <Header title="Personal" />
      <NavItem icon={<FaUserTag />} link="Get My Customers" />
      <Header title="Setting" />
      <NavItem icon={<FaUserCog />} link="Personal Information" />
    </div>
  );
};

export default Admin;
