import React, { useEffect, useState } from "react";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import Card from "../components/card/Card";
import CardBody from "../components/card/CardBody";
import CardHeader from "../components/card/CardHeader";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import { convert } from "../functions/Convert";
import { currencyFormat, dateFormat } from "../functions/Format";
import Divider from "../components/Divider";

const ViewReportPage = ({ history }) => {
  const { state } = history.location;

  const [loading, setLoading] = useState(true);

  const fullName = state && state.user.firstName + " " + state.user.lastName;
  const manager = state && state.completedAt.managerFullName;
  const report = state && JSON.parse(state.datas).state;
  const actions = state && JSON.parse(state.datas).actions;
  const year = state && JSON.parse(state.datas).year;
  const date = state && JSON.parse(state.datas).date;

  const handleReturn = () => {
    history.goBack();
  };
  useEffect(() => {
    !state ? handleReturn() : setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, state]);

  const renderSummary = (key, value) => {
    return (
      <tr key={key} className={value === year - 1 ? "lastTrBlue" : ""}>
        <td>{convert(key)} year</td>
        <td className="text-right">
          {currencyFormat(report[key].marketValues)}
        </td>
        <td className="text-right">{currencyFormat(report[key].mortgages)}</td>
        <td className="text-right">{currencyFormat(report[key].netValues)}</td>
        <td className="text-right">
          {currencyFormat(report[key].annualLiquidities)}
        </td>
        <td className="text-right">
          {currencyFormat(report[key].cumulativEnrichment)}
        </td>
      </tr>
    );
  };

  const renderProperties = (property, i) => {
    return (
      <Col md={5} key={i}>
        <Table striped bordered size="sm">
          <thead>
            <tr>
              <th colSpan="3">{property.name}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="lastTrOrange">
                Ratio of the market value of the building
              </td>
              <td className="lastTrBlue text-right">
                {property.multiplicationFactor}
              </td>
            </tr>
            <tr>
              <td className="lastTrOrange">
                Average growth rate of revenues and expenses
              </td>
              <td className="lastTrBlue text-right">{property.growthRate} %</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    );
  };

  const renderActionsProperties = (properties) => {
    return properties.map((property, i) => (
      <div key={i}>
        <h6>Purchase of properties</h6>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Purchasing Price</th>
              <th>Down Payement</th>
              <th>Tax Credit</th>
              <th>Bank Rebate</th>
              <th>Interest</th>
              <th>Amortization</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{property.name}</td>
              <td>{currencyFormat(parseInt(property.marketValue))}</td>
              <td>{currencyFormat(parseInt(property.downPayment))}</td>
              <td>{currencyFormat(parseInt(property.taxCredit))}</td>
              <td>{currencyFormat(parseInt(property.bankRebate))}</td>
              <td>{property.interest} %</td>
              <td>
                {property.amortization} year{property.amortization > 1 && "s"}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    ));
  };

  const renderActionsTransfert = (addCapital = [], sellTfsa = []) => {
    return (
      <div>
        <h6>Transfer between portfolios</h6>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Actions</th>
              <th>From</th>
              <th>To Payement</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>
            {addCapital.map((amount, i) => (
              <tr key={i}>
                <td>Add Capital</td>
                <td>Personnal</td>
                <td>Liquidity portfolio</td>
                <td>{currencyFormat(parseInt(amount))}</td>
              </tr>
            ))}
            {sellTfsa.map((amount, i) => (
              <tr key={i}>
                <td>Add Capital</td>
                <td>Tfsa</td>
                <td>Liquidity portfolio</td>
                <td>{currencyFormat(parseInt(amount))}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderRefinance = (refinances, properties) => {
    return (
      <div>
        <h6>Refinance</h6>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Free Capital</th>
              <th>Mortgage</th>
              <th>Interest</th>
              <th>Amortization</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(refinances).map((i) =>
              refinances[i].map((refinance) => (
                <tr key={i}>
                  <td>{properties[i].name}</td>
                  <td>{currencyFormat(parseInt(refinance.amount))}</td>
                  <td>{currencyFormat(parseInt(properties[i].dueValue))}</td>
                  <td>{refinance.interest} %</td>
                  <td>
                    {refinance.amortization} year
                    {refinance.amortization > 1 && "s"}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderYears = (year) => {
    return (
      <Card key={year}>
        <CardHeader title={convert(year) + " year"} />
        <CardBody>
          {Object.keys(actions[year]).length !== 0 ? (
            <div>
              <p>
                Here are the actions to carry out to realize your real estate
                project.
              </p>
              {actions[year]["addProperty"] &&
                renderActionsProperties(actions[year]["addProperty"])}
              {(actions[year]["addCapital"] || actions[year]["sellTfsa"]) &&
                renderActionsTransfert(
                  actions[year]["addCapital"],
                  actions[year]["sellTfsa"]
                )}
              {actions[year]["refinance"] &&
                renderRefinance(
                  actions[year]["refinance"],
                  report[year].properties
                )}
            </div>
          ) : (
            <p>There is no action to take this year</p>
          )}
          <Divider />
          <p>
            The following results are based on the values detailed in the
            scenario section and the execution of the action plan mentioned
            above.
          </p>
          <Table striped bordered>
            <tbody>
              <tr>
                <td className="lastTrOrange">Personal net worth</td>
                <td className="lastTrBlue text-right">
                  {currencyFormat(
                    report[year].availableEquities + report[year].netValues
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <Col md={6} className="p-0">
            <h6>Property project portfolio</h6>
            <Table striped bordered>
              <tbody>
                <tr className="lastTrOrange">
                  <td>Available equities</td>
                  <td className="text-right">
                    {currencyFormat(report[year].availableEquities)}
                  </td>
                </tr>
                <tr>
                  <td>Market value</td>
                  <td className="text-right">
                    {currencyFormat(report[year].marketValues)}
                  </td>
                </tr>
                <tr>
                  <td>Mortgage</td>
                  <td className="text-right">
                    {currencyFormat(report[year].mortgages)}
                  </td>
                </tr>
                <tr className="border-bottom-blue">
                  <td>Net value</td>
                  <td className="lastTrBlue text-right">
                    {currencyFormat(report[year].netValues)}
                  </td>
                </tr>
                <tr>
                  <td>Incomes</td>
                  <td className="text-right">
                    {currencyFormat(report[year].propertyIncome)}
                  </td>
                </tr>
                <tr>
                  <td>Expenses</td>
                  <td className="text-right">
                    {currencyFormat(report[year].operatingExpenses)}
                  </td>
                </tr>
                <tr>
                  <td>Monthly Payment</td>
                  <td className="text-right">
                    {currencyFormat(report[year].bankPromises)}
                  </td>
                </tr>
                <tr>
                  <td>Annual liquidity</td>
                  <td className="text-right">
                    {currencyFormat(report[year].annualLiquidities)}
                  </td>
                </tr>
                <tr className="lastTrBlue">
                  <td>Annual enrichment</td>
                  <td className="text-right">
                    {currencyFormat(report[year].annualEnrichments)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          {report[year].properties.length > 0 && (
            <div>
              <h6>Important values of buildings</h6>
              <Table striped bordered>
                <thead>
                  <tr className="lastTrOrange">
                    <th>Name</th>
                    <th>Value</th>
                    <th>Mortgage</th>
                    <th>Net Value</th>
                    <th>Increase In Market Value</th>
                  </tr>
                </thead>
                <tbody>
                  {report[year].properties.map((property, i) => (
                    <tr key={i}>
                      <td>{property.name}</td>
                      <td>{currencyFormat(property.marketValue2)}</td>
                      <td>
                        {currencyFormat(
                          property.dueValue
                            ? property.dueValue
                            : property.mortgage
                        )}
                      </td>
                      <td>{currencyFormat(property.netValue)}</td>
                      <td>{currencyFormat(property.increaseMarketValue)}</td>
                    </tr>
                  ))}
                  <tr className="lastTrBlue">
                    <td>Total</td>
                    <td>{currencyFormat(report[year].marketValues)}</td>
                    <td>{currencyFormat(report[year].mortgages)}</td>
                    <td>{currencyFormat(report[year].netValues)}</td>
                    <td>{currencyFormat(report[year].increaseMarketValues)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          {report[year].properties.length > 0 && (
            <div>
              <h6>Cash flows for the current year</h6>
              <Table striped bordered>
                <thead>
                  <tr className="lastTrOrange">
                    <th>Name</th>
                    <th>Income</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Expense</th>
                    <th>Annual Liquidity</th>
                    <th>Annual Enrichment</th>
                  </tr>
                </thead>
                <tbody>
                  {report[year].properties.map((property, i) => (
                    <tr key={i}>
                      <td>{property.name}</td>
                      <td>{currencyFormat(property.income)}</td>
                      <td>
                        {currencyFormat(property.sumPrincipalValueEndOfYear)}
                      </td>
                      <td>
                        {currencyFormat(property.sumInterestValueEndOfYear)}
                      </td>
                      <td>{currencyFormat(property.operatingExpense)}</td>
                      <td>{currencyFormat(property.annualLiquidity)}</td>
                      <td>{currencyFormat(property.annualEnrichment)}</td>
                    </tr>
                  ))}
                  <tr className="lastTrBlue">
                    <td>Total</td>
                    <td>{currencyFormat(report[year].propertyIncome)}</td>
                    <td>{currencyFormat(report[year].principalValues)}</td>
                    <td>{currencyFormat(report[year].interestValues)}</td>
                    <td>{currencyFormat(report[year].operatingExpenses)}</td>
                    <td>{currencyFormat(report[year].annualLiquidities)}</td>
                    <td>{currencyFormat(report[year].annualEnrichments)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  return loading ? (
    <LoadingInfo />
  ) : (
    <div className="report">
      <PageHeading title={dateFormat(state.completedAt.date)} />

      <Card>
        <CardHeader
          title={
            "Report of " +
            fullName +
            " by " +
            manager +
            " for " +
            dateFormat(date)
          }
        />
        <CardBody>
          <h6>Summary my real estate project to maturity.</h6>
          <Table striped bordered>
            <thead>
              <tr>
                <th colSpan="6" className="text-center">
                  My real estate project
                </th>
              </tr>
              <tr className="text-center">
                <th>Year</th>
                <th>Market Value</th>
                <th>Mortgage</th>
                <th>Net Value</th>
                <th>Annual Liquidity</th>
                <th>Cumulative Enrichment</th>
              </tr>
            </thead>
            <tbody>{Object.keys(report).map(renderSummary)}</tbody>
          </Table>
          <h6>
            The results of this simulation are obtained by considering the
            following factors.
          </h6>
          <Row>{report[year].properties.map(renderProperties)}</Row>
        </CardBody>
      </Card>
      {Object.keys(report).map(renderYears)}
      <Container fluid>
        <Button variant="secondary" className="my-3" onClick={handleReturn}>
          Return
        </Button>
      </Container>
    </div>
  );
};

export default ViewReportPage;
