import moment from "moment";
import { roundingValue } from "../../../functions/Format";

const marketValue = (data) => {
  const value = data[0];
  const multiplication = parseFloat(data[1]);
  return roundingValue(value * multiplication);
};

const netValue = (marketValue2, mortgage) => {
  return roundingValue(marketValue2 - mortgage);
};

const availableEquity = (netValue, marketValue2) => {
  return roundingValue(netValue - (marketValue2 * 20) / 100);
};

const monthlyPayment = (mortgage, amortization, interest) => {
  const duration = amortization * 12;
  const periodicalRate = interest / 100 / 12;

  return roundingValue(
    (mortgage * periodicalRate) / (1 - (1 + periodicalRate) ** (duration * -1))
  );
};

const bankPromise = (monthlyPayment) => {
  return roundingValue(monthlyPayment * 12);
};

const annualLiquidity = (income, operatingExpense, bankPromise) => {
  return roundingValue(income - operatingExpense - bankPromise);
};

const availableEquitiesCalculator = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.availableEquity));
  return roundingValue(result);
};

const sumIncomes = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.income));
  return roundingValue(result);
};

const sumAnnualEnrichments = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.annualEnrichment));
  return roundingValue(result);
};

const sumIncreaseMarketValues = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.increaseMarketValue));
  return roundingValue(result);
};

const sumExpenses = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.operatingExpense));
  return roundingValue(result);
};

const marketValuesCalculator = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.marketValue2));
  return roundingValue(result);
};

const annualLiquiditiesCalculator = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.annualLiquidity));

  return roundingValue(result);
};

const bankPromisesCalculator = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.bankPromise));

  return roundingValue(result);
};

const netValuesCalculator = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.netValue));
  return roundingValue(result);
};

const principalValuesCalculator = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.sumPrincipalValueEndOfYear));
  return roundingValue(result);
};

const interestValuesCalculator = (properties) => {
  let result = 0;
  properties.map((property) => (result += property.sumInterestValueEndOfYear));
  return roundingValue(result);
};

const mortgagesCalculator = (properties) => {
  let result = 0;
  // eslint-disable-next-line array-callback-return
  properties.map((property) => {
    if (property.dueValue) {
      result += property.dueValue;
    } else {
      result += property.mortgage;
    }
  });

  return roundingValue(result);
};

const growthRateCalculator = (value, growthRate) => {
  return roundingValue(value * (1 + growthRate / 100));
};

const celiCalculator = (celi, actions = {}) => {
  let result = celi;
  actions.sellTfsa &&
    actions.sellTfsa.map((amount) => (result -= parseFloat(amount)));
  return result;
};

const portfolioLiquidityCalculator = (
  portfolioLiquidity,
  annualLiquidities,
  actions
) => {
  let result = portfolioLiquidity + annualLiquidities;
  actions.sellTfsa &&
    actions.sellTfsa.map((amount) => (result += parseFloat(amount)));
  actions.addCapital &&
    actions.addCapital.map((amount) => (result += parseFloat(amount)));
  actions.addProperty &&
    // eslint-disable-next-line array-callback-return
    actions.addProperty.map((property) => {
      result -= parseFloat(property.downPayment);
      result += parseFloat(property.taxCredit);
      result += parseFloat(property.bankRebate);
    });
  if (actions.refinance) {
    Object.values(actions.refinance).map((values) =>
      values.map((value) => (result += parseFloat(value["amount"])))
    );
  }

  return result;
};

const mortgageCalculator = (purchasingPrice, downPayment) => {
  return parseFloat(purchasingPrice) - parseFloat(downPayment);
};

const refinanceCalculator = (actions = []) => {
  let result = 0;
  actions.map((action) => (result += parseFloat(action["amount"])));
  return result;
};

const dueValueCalculator = (monthlyPayment, dueValue, interest) => {
  const periodicalRate = interest / 100 / 12;
  let sumInterestValue = 0;
  let sumPrincipalValue = 0;

  for (let i = 1; i < 13; i++) {
    let interestValue = roundingValue(dueValue * periodicalRate);
    let principalValue = roundingValue(monthlyPayment - interestValue);
    dueValue -= principalValue;

    sumInterestValue += interestValue;
    sumPrincipalValue += principalValue;
  }

  return { sumInterestValue, sumPrincipalValue, dueValue };
};

const initPropertyCalculator = (property, actions = []) => {
  let refinance = refinanceCalculator(actions);
  let newProperty = {
    // Est ce une aquisition?
    isAquisition: property["@id"] ? false : true,
    // On récupère le nom de la propriété
    name: property.name,
    // On récupère l'hypotèque
    mortgage: property.mortgage
      ? parseFloat(property.mortgage)
      : mortgageCalculator(property.marketValue, property.downPayment),

    // On récupère les revenues de la propriété
    income: parseFloat(property.income),
    // On récupère le tx de progression de la propriété
    growthRate: parseFloat(property.growthRate),
    // On récupère la valeur marchande de la propriété
    marketValue: parseFloat(property.marketValue),
    // On récupère la durée d'amortisation de la propriété
    amortization:
      actions.length > 0
        ? parseFloat(actions[actions.length - 1].amortization)
        : parseFloat(property.amortization),
    // On récupère les dépenses de la propriété
    operatingExpense: parseFloat(property.operatingExpense),
    // On récupère le tx d'intérêt de la propriété
    interest:
      actions.length > 0
        ? parseFloat(actions[actions.length - 1].interest)
        : parseFloat(property.interest),
    // On récupère le facteur multiplicateur de la propriété
    multiplicationFactor: parseFloat(property.multiplicationFactor),
  };

  newProperty.mortgage += refinance;

  newProperty.marketValue2 = marketValue(
    newProperty.income
      ? [newProperty.income, newProperty.multiplicationFactor]
      : [newProperty.marketValue, newProperty.growthRate]
  );

  newProperty.netValue = netValue(
    newProperty.marketValue2,
    newProperty.mortgage
  );
  newProperty.availableEquity = availableEquity(
    newProperty.netValue,
    newProperty.marketValue2
  );
  newProperty.monthlyPayment = monthlyPayment(
    newProperty.mortgage,
    newProperty.amortization,
    newProperty.interest
  );

  newProperty.bankPromise = bankPromise(newProperty.monthlyPayment);
  newProperty.annualLiquidity = annualLiquidity(
    newProperty.income,
    newProperty.operatingExpense,
    newProperty.bankPromise
  );
  const { sumInterestValue, sumPrincipalValue } = dueValueCalculator(
    newProperty.monthlyPayment,
    newProperty.mortgage,
    newProperty.interest
  );

  newProperty.sumInterestValueEndOfYear = roundingValue(sumInterestValue);
  newProperty.sumPrincipalValueEndOfYear = roundingValue(sumPrincipalValue);
  newProperty.sumPrincipalValueEndOfYear = roundingValue(sumPrincipalValue);
  newProperty.increaseMarketValue =
    newProperty.marketValue2 - newProperty.marketValue;
  newProperty.annualEnrichment =
    newProperty.annualLiquidity +
    newProperty.sumPrincipalValueEndOfYear +
    newProperty.increaseMarketValue;
  return newProperty;
};

export const initCalculator = (state, actions) => {
  let newProperties = actions.addProperty
    ? state.properties.concat(actions.addProperty)
    : state.properties;

  const newState = {
    // On récupère le celi
    celi: celiCalculator(state.celi, actions),
    celiP: state.celiP ? 1 + state.celiP / 100 : 0,
    // On récupère le valeur de l'entreprise
    companyValue: state.companyValue,
    companyValueP: state.companyValueP ? 1 + state.companyValueP / 100 : 0,
    // On récupère le montant investie
    investedAmount: state.investedAmount,
    // On récupère le no reer
    noReer: state.noReer,
    noReerP: state.noReer ? 1 + state.noReer / 100 : 0,
    // On récupère le reer
    reer: state.reer,
    reerP: state.reerP ? 1 + state.reerP / 100 : 0,
    // On initialise  le portefeuille
    portfolioLiquidity: portfolioLiquidityCalculator(
      state.investedAmount,
      0,
      actions
    ),
  };
  newState.properties = newProperties.map((property, i) =>
    initPropertyCalculator(property, actions.refinance && actions.refinance[i])
  );
  newState.operatingExpenses = sumExpenses(newState.properties);
  newState.propertyIncome = sumIncomes(newState.properties);
  newState.availableEquities = availableEquitiesCalculator(newState.properties);
  newState.marketValues = marketValuesCalculator(newState.properties);
  newState.annualLiquidities = annualLiquiditiesCalculator(newState.properties);
  newState.bankPromises = bankPromisesCalculator(newState.properties);
  newState.netValues = netValuesCalculator(newState.properties);
  newState.principalValues = principalValuesCalculator(newState.properties);
  newState.interestValues = interestValuesCalculator(newState.properties);
  newState.mortgages = mortgagesCalculator(newState.properties);
  newState.annualEnrichments = sumAnnualEnrichments(newState.properties);
  newState.increaseMarketValues = sumIncreaseMarketValues(newState.properties);
  newState.cumulativEnrichment = newState.annualEnrichments;

  return newState;
};

export const verifState = (init) => {
  const arrayErrors = {};

  // On vérifie les données renseignées
  if (init.date) {
    !moment(init.date).isValid() &&
      (arrayErrors["date"] = "This value is not a date");
  } else {
    arrayErrors["date"] = "This value is required";
  }

  if (init.duration) {
    init.duration < 0 &&
      (arrayErrors["duration"] = "This value must be positive");
  } else {
    arrayErrors["duration"] = "This value is required";
  }

  if (init.properties) {
    // eslint-disable-next-line array-callback-return
    init.properties.map((property, i) => {
      if (property.growthRate) {
        property.growthRate < 0 &&
          (arrayErrors["growthRate" + i] = "This value must be positive");
      } else {
        arrayErrors["growthRate" + i] = "This value is required";
      }
      if (property.multiplicationFactor) {
        property.multiplicationFactor < 0 &&
          (arrayErrors["multiplicationFactor" + i] =
            "This value must be positive");
      } else {
        arrayErrors["multiplicationFactor" + i] = "This value is required";
      }
    });
  }

  return Object.keys(arrayErrors).length > 0 && arrayErrors;
};

const nextPropertyCalculator = (property, actions = []) => {
  let refinance = refinanceCalculator(actions);

  const { sumInterestValue, sumPrincipalValue, dueValue } = dueValueCalculator(
    property.monthlyPayment,
    property.dueValue || property.mortgage,
    property.interest
  );

  let newProperty = {
    // On récupère le nom de la propriété
    name: property.name,
    // On récupère l'hypotèque
    mortgage: refinance > 0 ? dueValue + refinance : property.mortgage,
    // On récupère le tx de progression de la propriété
    growthRate: property.growthRate,
    // On récupère la valeur marchande de la propriété
    marketValue: property.marketValue,
    // On récupère la durée d'amortisation de la propriété
    amortization:
      actions.length > 0
        ? parseFloat(actions[actions.length - 1].amortization)
        : parseFloat(property.amortization),
    // On récupère le tx d'intérêt de la propriété
    interest:
      actions.length > 0
        ? parseFloat(actions[actions.length - 1].interest)
        : parseFloat(property.interest),
    // On récupère le facteur multiplicateur de la propriété
    multiplicationFactor: property.multiplicationFactor,
  };

  newProperty.dueValue = roundingValue(dueValue) + refinance;
  newProperty.sumInterestValue = roundingValue(sumInterestValue);
  newProperty.sumPrincipalValue = roundingValue(sumPrincipalValue);

  newProperty.income = growthRateCalculator(
    property.income,
    newProperty.growthRate
  );

  newProperty.operatingExpense = growthRateCalculator(
    property.operatingExpense,
    newProperty.growthRate
  );
  newProperty.marketValue2 = growthRateCalculator(
    property.marketValue2,
    newProperty.growthRate
  );
  newProperty.netValue = netValue(
    newProperty.marketValue2,
    newProperty.dueValue
  );
  newProperty.availableEquity = availableEquity(
    newProperty.netValue,
    newProperty.marketValue2
  );
  newProperty.monthlyPayment = monthlyPayment(
    newProperty.mortgage,
    newProperty.amortization,
    newProperty.interest
  );
  newProperty.bankPromise = bankPromise(newProperty.monthlyPayment);
  newProperty.annualLiquidity = annualLiquidity(
    newProperty.income,
    newProperty.operatingExpense,
    newProperty.bankPromise
  );

  const {
    sumInterestValue: sumInterestValueEndOfYear,
    sumPrincipalValue: sumPrincipalValueEndOfYear,
  } = dueValueCalculator(
    newProperty.monthlyPayment,
    newProperty.dueValue || property.mortgage,
    newProperty.interest
  );

  newProperty.sumInterestValueEndOfYear = roundingValue(
    sumInterestValueEndOfYear
  );
  newProperty.sumPrincipalValueEndOfYear = roundingValue(
    sumPrincipalValueEndOfYear
  );

  newProperty.increaseMarketValue =
    newProperty.marketValue2 - property.marketValue2;
  newProperty.annualEnrichment =
    newProperty.annualLiquidity +
    newProperty.sumPrincipalValueEndOfYear +
    newProperty.increaseMarketValue;
  return newProperty;
};

export const nextYear = (state, actions) => {
  let newProperties = actions.addProperty
    ? actions.addProperty.map((property, i) =>
        initPropertyCalculator(
          property,
          actions.refinance && actions.refinance[i + state.properties.length]
        )
      )
    : [];

  const newState = {
    // On récupère le celi
    celiP: state.celiP,
    celi: celiCalculator(state.celi * state.celiP, actions),
    // On récupère le valeur de l'entreprise
    companyValueP: state.companyValueP,
    companyValue: state.companyValue * state.companyValueP,
    // On récupère le montant investie
    investedAmount: state.investedAmount,
    // On récupère le no reer
    noReer: state.noReer,
    noReerP: state.noReerP,
    // On récupère le reer
    reerP: state.reerP,
    reer: state.reer * state.reerP,
    // On calcule la valeur marchande
    portfolioLiquidity: portfolioLiquidityCalculator(
      state.portfolioLiquidity,
      state.annualLiquidities,
      actions
    ),
  };

  newState.properties = state.properties.map((property, i) =>
    nextPropertyCalculator(property, actions.refinance && actions.refinance[i])
  );
  newState.properties = newState.properties.concat(newProperties);
  newState.availableEquities = availableEquitiesCalculator(newState.properties);
  newState.marketValues = marketValuesCalculator(newState.properties);

  newState.annualLiquidities = annualLiquiditiesCalculator(newState.properties);
  newState.bankPromises = bankPromisesCalculator(newState.properties);
  newState.netValues = netValuesCalculator(newState.properties);
  newState.mortgages = mortgagesCalculator(newState.properties);
  newState.operatingExpenses = sumExpenses(newState.properties);
  newState.propertyIncome = sumIncomes(newState.properties);
  newState.principalValues = principalValuesCalculator(newState.properties);
  newState.interestValues = interestValuesCalculator(newState.properties);
  newState.increaseMarketValues = sumIncreaseMarketValues(newState.properties);
  newState.annualEnrichments = sumAnnualEnrichments(newState.properties);
  newState.cumulativEnrichment =
    state.cumulativEnrichment + newState.annualEnrichments;

  return newState;
};
