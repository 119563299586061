import React from "react";
import { Button, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { dateFormat } from "../../functions/Format";
import ReportAPI from "../../services/ReportAPI";
import CardBody from "../card/CardBody";
import Table from "../table/Table";

const RenderReports = ({ reports, setReports }) => {
  const handleDelete = async (id) => {
    const originalReports = [...reports];
    setReports(reports.filter((value) => value.id !== id));
    try {
      await ReportAPI.delete(id);
    } catch (error) {
      setReports(originalReports);
    }
  };
  const download = (report) => {
    ReportAPI.download(report.id);
  };

  return (
    <div className="container mt-5 mb-2">
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Completed At</th>
              <th>By</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, i) => (
              <tr key={report["@id"]}>
                <td>{i + 1}</td>
                <td>
                  {dateFormat(report.completedAt.date) +
                    " for " +
                    dateFormat(JSON.parse(report.datas).date)}
                </td>
                <td>{report.completedAt.managerFullName}</td>
                <td className="text-center">
                  <NavLink
                    to={{
                      pathname: "/view-report",
                      state: report,
                    }}
                    className="btn btn-change btn-primary"
                  >
                    View
                  </NavLink>
                  <Button
                    onClick={() => download(report)}
                    className="btn btn-change btn-success"
                  >
                    Download
                  </Button>
                  <Button
                    className="btn btn-change btn-danger"
                    onClick={() => handleDelete(report.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </div>
  );
};

export default RenderReports;
