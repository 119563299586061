import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Mailer from "../../services/Mailer";
import ManagerAPI from "../../services/ManagerAPI";
import Field from "../form/Field";

const AddManager = ({ show, setShow, managers, setManagers, company }) => {
  // Initialisation des données Managers
  const [manager, setManager] = useState({
    password: "",
    confirmPassword: "",
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
  });

  // Initialisation des erreurs
  const [errors, setErrors] = useState({});

  // Gestion de la modification d'un champs
  const handleChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setManager({ ...manager, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // Gestion affichage du modal
  const handleClose = () => {
    setManager({});
    setErrors({});
    setShow(false);
  };

  // Gestion de la validation du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiErrors = {};
    // Enregistrement des managers existants
    const originalManagers = [...managers];
    try {
      managers.unshift(await ManagerAPI.addManager(manager));
      // On les ajoute aux managers existants
      setManagers(managers);
      // On initialise les données à vide
      setManager({});
      Mailer.sendMail("manager_registration", {
        email: manager.email,
        username: manager.firstName + " " + manager.lastName,
        company,
      });
      // On ferme le modal
      setShow(false);
    } catch (error) {
      // On remet les managers existants
      setManagers(originalManagers);
      // On set les erreurs
      const { violations } = error.response.data;
      if (violations) {
        violations.forEach((violation) => {
          apiErrors[violation.propertyPath] = violation.message;
        });
        setErrors(apiErrors);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Manager's Information</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Field
                id="firstName"
                value={manager.firstName}
                placeholder="First name"
                onChange={handleChange}
                error={errors.firstName}
              />
            </Col>
            <Col>
              <Field
                id="lastName"
                value={manager.lastName}
                placeholder="Name"
                onChange={handleChange}
                error={errors.lastName}
              />
            </Col>
          </Row>
          <Field
            id="email"
            type="email"
            value={manager.email}
            placeholder="Email"
            onChange={handleChange}
            error={errors.email}
          />
          <Field
            id="phone"
            value={manager.phone}
            placeholder="Phone number"
            onChange={handleChange}
            error={errors.phone}
          />
          <Field
            id="password"
            type="password"
            value={manager.password}
            placeholder="Password"
            onChange={handleChange}
            error={errors.password}
          />
          <Field
            id="confirmPassword"
            type="password"
            value={manager.confirmPassword}
            placeholder="Confirm password"
            onChange={handleChange}
            error={errors.confirmPassword}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Add manager
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddManager;
