import React from "react";

const Table = ({ children }) => {
  return (
    <div className="table-responsive">
      <table
        className="table table-bordered"
        id="dataTable"
        width="100%"
        cellSpacing="0"
      >
        {children}
      </table>
    </div>
  );
};

export default Table;
