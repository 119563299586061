import React, { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import CompanyAPI from "../services/CompanyAPI";
import ManagerAPI from "../services/ManagerAPI";

const ManageCompany = ({ match, history }) => {
  // On récupère le nom passé en url
  const { id } = match.params;
  // On récupère l'id passé dans le state
  const { state } = history.location;
  const [loading, setLoading] = useState(false);

  const [assign, setAssign] = useState(state ? state.isChecked : null);
  const [managers, setManagers] = useState([]);

  const fetchManagers = async () => {
    try {
      setManagers(await CompanyAPI.companyManagers(state.id));
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };
  const handleChange = (id) => {
    setAssign(id);
  };

  const handleReturn = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      assign && (await ManagerAPI.assignAdmin(assign));
      state.isChecked &&
        state.isChecked !== assign &&
        (await ManagerAPI.unAssignAdmin(state.isChecked));
      handleReturn();
    } catch (error) {
      setLoading(false);
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  useEffect(() => {
    state ? fetchManagers() : handleReturn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, state]);
  return loading ? (
    <LoadingInfo />
  ) : (
    <div>
      <PageHeading title={"Choose an administrator for " + id}></PageHeading>
      <Container>
        <div className="mb-3">
          <Form.Check
            inline
            label={"None"}
            name="managers"
            type="radio"
            id={null}
            checked={assign === null}
            onChange={() => handleChange(null)}
          />
        </div>
        {managers.map((manager) => (
          <div key={manager["@id"]} className="mb-3">
            <Form.Check
              inline
              label={manager.firstName + " " + manager.lastName}
              name="managers"
              type="radio"
              id={manager["@id"]}
              checked={assign === manager.id}
              onChange={() => handleChange(manager.id)}
            />
          </div>
        ))}

        <Button
          onClick={handleSubmit}
          className="mr-1"
          variant="primary"
          disabled={loading}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "5px", marginBottom: "2px" }}
              size="sm"
              animation="border"
            />
          )}
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleReturn}>
          Cancel
        </Button>
      </Container>
    </div>
  );
};

export default ManageCompany;
