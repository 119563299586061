import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  FaCheckCircle,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa";
import StateContext from "../../context/StateContext";
import { convert } from "../../functions/Convert";
import ReportAPI from "../../services/ReportAPI";
import LoadingInfo from "../loading/LoadingInfo";
import PageHeading from "../pageHeading/PageHeading";
import { initCalculator, nextYear } from "./functions/VerifState";
import RenderProperties from "./RenderProperties";
import RenderState from "./RenderState";

const RenderSimulation = ({ children, history, customerId }) => {
  const [loading, setLoading] = useState(true);
  const { state, setState, year, setYear, setActions, actions } =
    useContext(StateContext);

  const save = async () => {
    try {
      await ReportAPI.save(
        state.id,
        JSON.stringify({ state: state, actions: actions, year: year })
      );
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  const handleNext = () => {
    setLoading(true);
    !actions[year + 1] && setActions({ ...actions, [year + 1]: {} });
    setYear(year + 1);
  };

  const handleComplet = async () => {
    const id = state.id;
    const date = state.date;
    try {
      delete state[0];
      delete state.id;
      delete state.date;
      delete state.duration;
      await ReportAPI.complete(
        id,
        JSON.stringify({ state, actions, year, date })
      );
      history.replace("/reports/" + customerId);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  const handlePrevious = () => {
    setYear(year - 1);
  };
  const handleReturn = () => {
    history.goBack();
  };

  useEffect(() => {
    if (year === 1) {
      setState({
        ...state,
        [year]: initCalculator(state[year - 1], actions[year]),
      });
      save();
      setLoading(false);
    } else {
      setState({
        ...state,
        [year]: nextYear(state[year - 1], actions[year]),
      });
      save();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, actions[year]]);
  return loading ? (
    <LoadingInfo />
  ) : (
    <div>
      <PageHeading title={convert(year) + " year"} />
      <RenderState state={state[year]} />
      <RenderProperties properties={state[year].properties} />
      {children}
      <Row className="mt-3 justify-content-between m-0">
        <Col className="text-left">
          <Button onClick={handlePrevious}>
            <FaChevronCircleLeft className="mx-2" />
            {year === 1
              ? "Initilization planning"
              : "Back to " + convert(year - 1) + " year"}
          </Button>
        </Col>
        <Col className="text-center">
          {/* Modal cancel  */}
          <Button variant="secondary" onClick={handleReturn}>
            <Row className="align-items-center m-0">Cancel</Row>
          </Button>
        </Col>
        <Col className="text-end">
          <Button onClick={year < state.duration ? handleNext : handleComplet}>
            {year < state.duration
              ? "Go to the " + convert(year + 1)
              : "Complet"}
            {year < state.duration ? (
              <FaChevronCircleRight className="mx-2" />
            ) : (
              <FaCheckCircle className="mx-2" />
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RenderSimulation;
