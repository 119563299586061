import React from "react";
import { FaBuilding, FaUserCog, FaUserFriends, FaUsers } from "react-icons/fa";
import Header from "./Header";
import NavItem from "./NavItem";

const SuperAdmin = () => {
  return (
    <div>
      <Header title="Administration" />
      <NavItem icon={<FaBuilding />} link="Companies" />
      <NavItem icon={<FaUserFriends />} link="Managers" />
      <NavItem icon={<FaUsers />} link="Customers" />
      <Header title="Setting" />
      <NavItem icon={<FaUserCog />} link="Personal Information" />
    </div>
  );
};

export default SuperAdmin;
