import React from "react";
import Card from "react-bootstrap/Card";

const RenderNoState = () => {
  return (
    <div className="container mt-5 mb-2">
      <Card>
        <Card.Body>
          Your customer has not completed his financial statement, there is no
          data to use.
        </Card.Body>
      </Card>
    </div>
  );
};

export default RenderNoState;
