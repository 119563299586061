import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import LoadingInfo from "../components/loading/LoadingInfo";
import ReportAPI from "../services/ReportAPI";
import AddCapital from "../components/simulation/actions/AddCapital";
import AddProperty from "../components/simulation/actions/AddProperty";
import RenderAddCapital from "../components/simulation/actions/RenderAddCapital";
import RenderSellCeli from "../components/simulation/actions/RenderSellCeli";
import SellCeli from "../components/simulation/actions/SellCeli";
import RenderSimulation from "../components/simulation/RenderSimulation";
import SimulationPlanning from "../components/simulation/SimulationPlanning";
import StateContext from "../context/StateContext";

const SimulationPage = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  // Récupération de l'id du rapport
  const { id } = match.params;
  // Récupération de l'id de l'état financier
  const { customerId } = history.location;

  // Initilisation de l'année à 1
  const [year, setYear] = useState(0);

  // Initialisation du rapport
  const [state, setState] = useState({});
  const [show, setShow] = useState("");
  const [actions, setActions] = useState({});
  const startReport = async () => {
    try {
      const report = await ReportAPI.create(customerId);
      setState({
        id: report.id,
        0: JSON.parse(report.datas),
      });
      setActions({ ...actions });
      setLoading(false);
    } catch (error) {
      history.goBack();
    }
  };

  const recoverReport = async () => {
    try {
      const report = await ReportAPI.find(id);
      if (JSON.parse(report.datas).state) {
        setState(JSON.parse(report.datas).state);
        setActions(
          JSON.parse(report.datas).actions || {
            ...actions,
          }
        );
        setYear(JSON.parse(report.datas).year || 0);
      } else {
        setState({
          id: report.id,
          0: JSON.parse(report.datas),
        });
        setActions({
          ...actions,
        });
      }
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    id === "new" ? startReport() : recoverReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return loading ? (
    <LoadingInfo />
  ) : (
    <StateContext.Provider
      value={{ state, setState, year, setYear, actions, setActions }}
    >
      <div className="container pb-5">
        {year === 0 ? (
          <SimulationPlanning history={history} />
        ) : (
          <RenderSimulation history={history} customerId={customerId}>
            <Card className="mt-3">
              <Card.Header>
                <Row className="align-items-center">
                  <div>Your actions</div>
                  <div>
                    <Button
                      variant="success"
                      onClick={() => setShow("sellCeli")}
                      disabled={actions.totalSellTfsa === 0}
                      size="sm"
                      className="text-uppercase mx-1"
                    >
                      Sell Tfsa
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => setShow("addCapital")}
                      size="sm"
                      className="text-uppercase mx-1"
                    >
                      Add Capital
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => setShow("addProperty")}
                      size="sm"
                      className="text-uppercase mx-1"
                    >
                      Building Aquisition
                    </Button>
                  </div>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  {actions[year] &&
                    actions[year].sellTfsa &&
                    actions[year].sellTfsa.map((sellTfsa, i) => (
                      <Col md={3} key={i}>
                        <RenderSellCeli key={i} sellTfsa={sellTfsa} i={i} />
                      </Col>
                    ))}
                  {actions[year] &&
                    actions[year].addCapital &&
                    actions[year].addCapital.map((addCapital, i) => (
                      <Col md={3} key={i}>
                        <RenderAddCapital
                          key={i}
                          addCapital={addCapital}
                          i={i}
                        />
                      </Col>
                    ))}
                </Row>
              </Card.Body>
            </Card>
          </RenderSimulation>
        )}
      </div>
      {show === "sellCeli" && <SellCeli show={true} setShow={setShow} />}
      {show === "addCapital" && <AddCapital show={true} setShow={setShow} />}
      {show === "addProperty" && <AddProperty show={true} setShow={setShow} />}
    </StateContext.Provider>
  );
};

export default SimulationPage;
