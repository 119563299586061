/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "../components/table/Table";
import Card from "../components/card/Card";
import CardBody from "../components/card/CardBody";
import CardHeader from "../components/card/CardHeader";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import CompanyAPI from "../services/CompanyAPI";
import { Button, Container } from "react-bootstrap";

const ViewInformationPage = ({ history, match }) => {
  // On récupère le nom passé en url
  const { id } = match.params;
  // On récupère l'id passé dans le state
  const { state } = history.location;

  const [loading, setLoading] = useState(true);

  const [managers, setManagers] = useState([]);

  const fetchManagers = async () => {
    try {
      setManagers(await CompanyAPI.companyManagers(state.id));
      setLoading(false);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  const handleReturn = () => {
    history.goBack();
  };

  useEffect(() => {
    state ? fetchManagers() : handleReturn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, state]);
  return loading ? (
    <LoadingInfo />
  ) : (
    <div>
      <PageHeading title={id + "'s information"} />
      <Card>
        <CardHeader title="Managers list" />
        <CardBody>
          {loading ? (
            <div className="relative pb-5">
              <LoadingInfo />
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {managers.length > 0 ? (
                  managers.map((manager) => (
                    <tr key={manager.id}>
                      <td>
                        {manager.firstName} {manager.lastName}
                      </td>
                      <td>
                        <a href={"mailto:" + manager.email}>{manager.email}</a>
                      </td>
                      <td>{manager.phone}</td>
                      <td>{manager.role}</td>
                      <td className="text-center"></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">The company has no registered managers</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      <Container fluid>
        <Button variant="secondary" onClick={handleReturn}>
          Return
        </Button>
      </Container>
    </div>
  );
};

export default ViewInformationPage;
