import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import StateContext from "../../../context/StateContext";

import PropertyForm from "../../form/PropertyForm";

const AddProperty = ({ show, setShow }) => {
  const { actions, setActions, year } = useContext(StateContext);
  const [property, setProperty] = useState({
    name: "",
    marketValue: "",
    downPayment: "",
    interest: "",
    amortization: "",
    income: "",
    operatingExpense: "",
    taxCredit: "",
    bankRebate: "",
    multiplicationFactor: "",
    growthRate: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    marketValue: "",
    downPayment: "",
    interest: "",
    amortization: "",
    income: "",
    operatingExpense: "",
    taxCredit: "",
    bankRebate: "",
    multiplicationFactor: "",
    growthRate: "",
  });

  const handleSubmit = () => {
    // 1. On vérifie que les données aient bien été remplis
    const object = {};
    for (let [key, value] of Object.entries(property)) {
      value.trim() === "" && (object[key] = "Required field");
      key !== "name" && value < 0 && (object[key] = "Value must be positive");
    }

    //Gestion des erreurs
    if (Object.keys(object).length !== 0) {
      setErrors(object);
    } else {
      actions[year].addProperty
        ? setActions({
            ...actions,
            [year]: {
              ...actions[year],
              addProperty: [...actions[year].addProperty, property],
            },
          })
        : setActions({
            ...actions,
            [year]: {
              ...actions[year],
              addProperty: [property],
            },
          });
      setShow(false);
    }
  };
  return (
    <Modal size="lg" show={show} onHide={() => setShow("")}>
      <Modal.Header closeButton>
        <Modal.Title>Building Aquisition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PropertyForm
          property={property}
          setProperty={setProperty}
          errors={errors}
          setErrors={setErrors}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow("")}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Buy
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProperty;
