import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import ManagerContext from "../../context/ManagerContext";

const Brand = () => {
  const { manager } = useContext(ManagerContext);

  return (
    <NavLink
      className="sidebar-brand d-flex align-items-center justify-content-center"
      to=""
    >
      <div className="sidebar-brand-icon rotate-n-15">
        <i className="fas fa-laugh-wink"></i>
      </div>
      <div className="sidebar-brand-text mx-3 mt-3">
        InoSimulation <p className="f-12 lighter">({manager.companyName})</p>
      </div>
    </NavLink>
  );
};

export default Brand;
