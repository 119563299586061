import Axios from "axios";
import { CUSTOMERS_API } from "../config";

function find(id) {
  return Axios.get(CUSTOMERS_API + "/" + id).then((response) => response.data);
}

function findAll() {
  return Axios.get(CUSTOMERS_API).then(
    (response) => response.data["hydra:member"]
  );
}

function assignCompany(id, companyId) {
  let apiCompany = "/api/aino/companies/" + companyId;
  return Axios.put(CUSTOMERS_API + "/" + id, {
    company: companyId === null ? null : apiCompany,
  }).then((response) => response.data);
}

function deleteUserAino(id) {
  return Axios.delete(CUSTOMERS_API + "/" + id).then(
    (response) => response.data["hydra:member"]
  );
}

function assignManager(state, managerId) {
  let apiManager = "/api/aino/managers/" + managerId;
  return Axios.put(CUSTOMERS_API + "/" + state.id, {
    properties: state.properties,
    manager: managerId === null ? null : apiManager,
  }).then((response) => response.data["hydra:member"]);
}

function getReports(id) {
  return Axios.get(CUSTOMERS_API + "/" + id + "/reports").then(
    (response) => response.data["hydra:member"]
  );
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  find,
  findAll,
  assignCompany,
  deleteUserAino,
  assignManager,
  getReports,
};
