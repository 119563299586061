import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import RenderNoReports from "../components/reports/RenderNoReports";
import RenderReports from "../components/reports/RenderReports";
import CustomerAPI from "../services/CustomerAPI";

const ReportsPage = ({ history, match }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(true);

  const [reports, setReports] = useState(null);
  const [fullName, setFullName] = useState(null);

  const fetchReports = async () => {
    try {
      const { firstName, lastName } = await CustomerAPI.find(id);
      setReports(await CustomerAPI.getReports(id));
      setFullName(firstName + " " + lastName);
      setLoading(false);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  const handleReturn = () => {
    history.goBack();
  };

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <LoadingInfo />
  ) : (
    <div>
      <PageHeading title={fullName + "'s reports"}></PageHeading>
      {reports.length > 0 ? (
        <RenderReports reports={reports} setReports={setReports} />
      ) : (
        <RenderNoReports />
      )}
      <Container>
        <Button variant="secondary" className="my-3" onClick={handleReturn}>
          Return
        </Button>
      </Container>
    </div>
  );
};

export default ReportsPage;
