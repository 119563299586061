import React, { useContext } from "react";
import { Container, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import PageHeading from "../components/pageHeading/PageHeading";
import ManagerContext from "../context/ManagerContext";
import Mailer from "../services/Mailer";
import RealoadRequestAPI from "../services/ReloadRequestAPI";

const RechargeByManager = ({ history }) => {
  const { manager, setManager } = useContext(ManagerContext);
  const managers = [...manager.reloadRequestId.managers, manager["@id"]];
  const handleAsking = async () => {
    try {
      const response = await RealoadRequestAPI.edit(
        manager.reloadRequestId.id,
        managers
      );
      setManager({ ...manager, reloadRequest: response });
      Mailer.sendMail("reload_request", {
        email: manager.administradorMail,
        username: manager.firstName + " " + manager.lastName,
      });
      toast.success("A request has been sent to your administrator !", {
        autoClose: 1000,
      });
      handleReturn();
    } catch (error) {}
  };
  const handleReturn = () => {
    history.goBack();
  };
  return (
    <div>
      <PageHeading title="Recharge your account" />
      <Container>
        <div className="mb-3">
          {manager.reloadRequest
            ? "You have already requested a refill, please contact your administrator."
            : "You are not authorized to recharge the company's account, please contact your administrator."}
        </div>
        <Button
          variant="primary"
          onClick={handleAsking}
          disabled={manager.reloadRequest}
        >
          Ask to the admin?
        </Button>
        <Button variant="secondary" className="mx-3" onClick={handleReturn}>
          Return
        </Button>
      </Container>
    </div>
  );
};

export default RechargeByManager;
