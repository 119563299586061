/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FaEnvelope, FaKey, FaMobile } from "react-icons/fa";
import Card from "../components/card/Card";
import CardBody from "../components/card/CardBody";
import CardHeader from "../components/card/CardHeader";
import PageHeading from "../components/pageHeading/PageHeading";
import Divider from "../components/sidebar/Divider";
import ManagerContext from "../context/ManagerContext";

const PersonalPage = () => {
  const { manager } = useContext(ManagerContext);

  return (
    <div>
      <PageHeading title="Overview" />
      <Card>
        <CardHeader title="Personal Information" />
        <CardBody>
          <h3 className="text-center mb-0">
            {manager.firstName} {manager.lastName}
          </h3>
          <p className="text-center mb-0">{manager.role}</p>
          <div className="block-line text-center mb-5 justify-content-center">
            <FaMobile />
            <p className="mb-0">{manager.phone}</p>
          </div>
          <Divider />
          <Row className="mt-5 mx-0 align-items-center">
            <Col md={3}>
              <h6 className="text-uppercase">login information :</h6>
            </Col>
            <Col md={9}>
              <div className="block-line mb-0">
                <FaEnvelope />
                <p className="mb-0">{manager.email}</p>
              </div>
              <div className="block-line mb-0">
                <FaKey />
                <p className="mb-0">********</p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default PersonalPage;
