import Axios from "axios";
import { ADMIN_URL, MANAGERS_API } from "../config";

function create(administrador) {
  return Axios.post(ADMIN_URL + "/create_company", administrador);
}

function addManager(manager) {
  return Axios.post(ADMIN_URL + "/create_manager", manager).then(
    (response) => response.data
  );
}

function assignAdmin(id) {
  return Axios.put(MANAGERS_API + "/" + id, { roles: ["ROLE_ADMIN"] }).then(
    (response) => response.data
  );
}

function unAssignAdmin(id) {
  return Axios.put(MANAGERS_API + "/" + id, { roles: ["ROLE_MANAGER"] }).then(
    (response) => response.data
  );
}

function deleteManager(id) {
  return Axios.delete(MANAGERS_API + "/" + id).then(
    (response) => response.data["hydra:member"]
  );
}

function managerCustomers(managerId) {
  return Axios.get(MANAGERS_API + "/" + managerId + "/customers").then(
    (response) => response.data["hydra:member"]
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create,
  addManager,
  assignAdmin,
  unAssignAdmin,
  delete: deleteManager,
  managerCustomers,
};
