import React from "react";
import { Col, Row } from "react-bootstrap";
import Field from "../form/Field";

const CompanyInformation = ({
  setAdministrador,
  administrador,
  errors,
  setErrors,
}) => {
  // Gestion des changements
  const handleChange = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setAdministrador({
      ...administrador,
      company: { ...administrador.company, [name]: value },
    });
    setErrors({ ...errors, company: { ...errors.company, [name]: "" } });
  };
  return (
    <div>
      <p>Company information</p>
      <Row>
        <Col md={6}>
          <Field
            id="name"
            value={administrador.company.name}
            placeholder="Company name"
            onChange={handleChange}
            error={errors.company.name}
          />
        </Col>
        <Col md={6}>
          <Field
            id="address"
            value={administrador.company.address}
            placeholder="Address"
            onChange={handleChange}
            error={errors.company.address}
          />
        </Col>
        <Col md={6}>
          <Field
            id="zipCode"
            value={administrador.company.zipCode}
            placeholder="Postal code"
            onChange={handleChange}
            error={errors.company.zipCode}
          />
        </Col>
        <Col md={6}>
          <Field
            id="city"
            value={administrador.company.city}
            placeholder="City"
            onChange={handleChange}
            error={errors.company.city}
          />
        </Col>
        <Col md={6}>
          <Field
            id="province"
            value={administrador.company.province}
            placeholder="Province"
            onChange={handleChange}
            error={errors.company.province}
          />
        </Col>
        <Col md={6}>
          <Field
            id="country"
            value={administrador.company.country}
            placeholder="Country"
            onChange={handleChange}
            error={errors.company.country}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyInformation;
