import React, { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import PageHeading from "../components/pageHeading/PageHeading";
import CompanyAPI from "../services/CompanyAPI";
import CustomerAPI from "../services/CustomerAPI";

const ManageCustomer = ({ match, history }) => {
  // On récupère le nom passé en url
  const { id } = match.params;
  // On récupère l'id passé dans le state
  const { state } = history.location;
  const [loading, setLoading] = useState(false);
  const [assign, setAssign] = useState(state && state.isChecked);
  const [companies, setCompanies] = useState([]);

  const fetchCompanies = async () => {
    try {
      setCompanies(await CompanyAPI.findAll());
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  const handleChange = (id) => {
    setAssign(id);
  };

  const handleReturn = () => {
    history.goBack();
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await CustomerAPI.assignCompany(state.id, assign);
      handleReturn();
    } catch (error) {
      setLoading(false);
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  useEffect(() => {
    state ? fetchCompanies() : handleReturn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, id, state]);

  return (
    <div>
      <PageHeading title={"Choose a company for " + id}></PageHeading>
      <Container>
        <div className="mb-3">
          <Form.Check
            inline
            label={"None"}
            name="companies"
            type="radio"
            id={null}
            checked={assign === null}
            onChange={() => handleChange(null)}
          />
        </div>
        {companies.map((company) => (
          <div key={company["@id"]} className="mb-3">
            <Form.Check
              inline
              label={company.name + " - (" + company.administrador + ")"}
              name="companies"
              type="radio"
              id={company["@id"]}
              checked={assign === company.id}
              onChange={() => handleChange(company.id)}
            />
          </div>
        ))}

        <Button
          onClick={handleSubmit}
          className="mr-1"
          variant="primary"
          disabled={loading}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "5px", marginBottom: "2px" }}
              size="sm"
              animation="border"
            />
          )}
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleReturn}>
          Cancel
        </Button>
      </Container>
    </div>
  );
};

export default ManageCustomer;
