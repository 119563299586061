import React from "react";
import { useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CustomerAPI from "../../services/CustomerAPI";

const RenderCustomerForSuperAdmin = ({ customers, setCustomers, data }) => {
  const customer = data;
  const [confirm, setConfirm] = useState(false);

  const handleConfirm = () => {
    setConfirm(!confirm);
  };

  const handleDelete = async () => {
    const originalCustomers = [...customers];
    setCustomers(customers.filter((value) => value["@id"] !== customer["@id"]));
    try {
      await CustomerAPI.deleteUserAino(data.id);
    } catch (error) {
      setCustomers(originalCustomers);
    }
  };

  return (
    <tr>
      <td>
        {customer.firstName} {customer.lastName}
      </td>
      <td>{customer.email}</td>
      <td>{customer.companyName}</td>
      <td className="text-center">
        <NavLink
          to={{
            pathname: "/customers/" + customer.id,
            state: {
              id: customer.stateId,
              fullName: customer.firstName + " " + customer.lastName,
            },
          }}
          className="btn btn-change btn-primary"
        >
          View
        </NavLink>
        <NavLink
          to={{
            pathname:
              "/choose-a-company/" +
              customer.firstName +
              "-" +
              customer.lastName,
            state: {
              id: customer.id,
              isChecked: customer.companyId,
            },
          }}
          className="btn btn-change btn-success"
        >
          Manage
        </NavLink>
        <Button onClick={handleConfirm} className="btn-change" variant="danger">
          {confirm ? "Cancel" : "Delete"}
        </Button>
        {confirm && (
          <div className="toast-confirm">
            <Toast show={confirm} className="d-inline-block m-1">
              <Toast.Body className={"text-danger f-12"}>
                By deleting users, you will automatically delete their
                associated data.
              </Toast.Body>
              <Button className="btn-change mb-1" onClick={handleDelete}>
                Confirm
              </Button>
            </Toast>
          </div>
        )}
      </td>
    </tr>
  );
};

export default RenderCustomerForSuperAdmin;
