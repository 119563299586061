import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import StateContext from "../../../context/StateContext";

const AddCapital = ({ show, setShow }) => {
  const { year, actions, setActions } = useContext(StateContext);
  const [amount, setAmount] = useState("");
  // Gestion des erreurs
  const [error, setError] = useState("");
  // Gestion du changement de montant
  const handleChange = (values) => {
    const { value } = values;
    setAmount(value);
    setError(false);
  };
  const handleSubmit = () => {
    // On vérfifie que le montant est inférieur ou égalie au celi disponible
    if (amount && amount >= 0) {
      actions[year].addCapital
        ? setActions({
            ...actions,
            [year]: {
              ...actions[year],
              addCapital: [...actions[year].addCapital, amount],
            },
          })
        : setActions({
            ...actions,
            [year]: {
              ...actions[year],
              addCapital: [amount],
            },
          });
      setShow(false);
    } else {
      setError(true);
    }
  };
  return (
    <Modal show={show} onHide={() => setShow("")}>
      <Modal.Header closeButton>
        <Modal.Title>Add capital</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Amount *</label>
        <CurrencyFormat
          onValueChange={handleChange}
          className="form-control"
          value={amount}
          id="addCapital"
          thousandSeparator={true}
          suffix={" CA$"}
        />
        {error && <p className="f-12 text-danger">Value must be positive</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow("")}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Add capital
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCapital;
