import Axios from "axios";
import { ADMIN_URL } from "../config";

function create(transaction) {
  return Axios.post(ADMIN_URL + "/buy_credit", transaction).then(
    (response) => response.data["hydra:member"]
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create,
};
