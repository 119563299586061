import React from "react";

export default React.createContext({
  state: null,
  setState: (value) => {},
  year: null,
  setYear: (value) => {},
  actions: null,
  setActions: (value) => {},
});
