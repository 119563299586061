import React, { useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PageHeading from "../components/pageHeading/PageHeading";
import ManagerContext from "../context/ManagerContext";

const DashboardPage = () => {
  const { manager } = useContext(ManagerContext);

  return (
    <div>
      <PageHeading title="Dashboard">
        <NavLink to={"/recharge"} className="btn btn-primary">
          Recharge account
        </NavLink>
      </PageHeading>
      <Container>
        {manager.role === "Administrador" &&
          manager.reloadRequestId.managers.length > 0 && (
            <div className="alert alert-warning text-center" role="alert">
              You have {manager.reloadRequestId.managers.length} manager
              {manager.reloadRequestId.managers.length > 1 && "s"} who require a
              refill
            </div>
          )}
        <Row>
          {manager.datas.map((data, i) => (
            <Col md={4} key={i} className="mt-2">
              <Card border="primary">
                <Card.Header>{data.name}</Card.Header>
                <Card.Body>
                  <Card.Text>
                    {data.info} : {data.value}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default DashboardPage;
