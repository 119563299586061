import React, { useEffect, useState } from "react";
import Card from "../components/card/Card";
import CardBody from "../components/card/CardBody";
import CardHeader from "../components/card/CardHeader";
import RenderCustomerForSuperAdmin from "../components/customers/RenderCustomerForSuperAdmin";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import Table from "../components/table/Table";
import CustomerAPI from "../services/CustomerAPI";

const AllCustomersPage = () => {
  const [loading, setLoading] = useState(true);

  // On initialise les clients à vide
  const [customers, setCustomers] = useState(null);
  // Permet de récupérer les clients
  const fetchCustomers = async () => {
    try {
      setCustomers(await CustomerAPI.findAll());
      setLoading(false);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeading title="Customers"></PageHeading>
      <Card>
        <CardHeader title="Customers list" />
        <CardBody>
          {loading ? (
            <div className="relative pb-5">
              <LoadingInfo />
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Company</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {customers.length > 0 ? (
                  <>
                    {customers.map((customer) => (
                      <RenderCustomerForSuperAdmin
                        key={customer["@id"]}
                        data={customer}
                        customers={customers}
                        setCustomers={setCustomers}
                      />
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="5">Your customer list is empty</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AllCustomersPage;
