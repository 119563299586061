import React, { useContext, useEffect, useState } from "react";
import Card from "../components/card/Card";
import CardBody from "../components/card/CardBody";
import CardHeader from "../components/card/CardHeader";
import RenderCustomer from "../components/customers/RenderCustomer";
import LoadingInfo from "../components/loading/LoadingInfo";
import PageHeading from "../components/pageHeading/PageHeading";
import Table from "../components/table/Table";
import ManagerContext from "../context/ManagerContext";
import CompanyAPI from "../services/CompanyAPI";
import ManagerAPI from "../services/ManagerAPI";

const CustomersPage = ({ history }) => {
  const { pathname } = history.location;
  const [loading, setLoading] = useState(true);
  // On récupère le user connecté
  const { manager } = useContext(ManagerContext);
  // On initialise les clients à vide
  const [customers, setCustomers] = useState(null);
  // Permet de récupérer les clients
  const fetchCustomers = async () => {
    try {
      pathname === "/customers"
        ? setCustomers(await CompanyAPI.companyCustomers(manager.companyId))
        : setCustomers(await ManagerAPI.managerCustomers(manager.id));
      setLoading(false);
    } catch (error) {
      console.log(
        "An error has occurred, please contact your administrator and specify the action you wish to take."
      );
    }
  };
  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager]);

  return (
    <>
      <PageHeading title="Customers"></PageHeading>
      <Card>
        <CardHeader title="Customers list" />
        <CardBody>
          {loading ? (
            <div className="relative pb-5">
              <LoadingInfo />
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  {pathname === "/customers" && <th>Manager</th>}
                  <th className="text-center">Reports</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {customers.length > 0 ? (
                  <>
                    {customers.map((customer) => (
                      <RenderCustomer
                        key={customer.id}
                        data={customer}
                        role={manager.role}
                        pathname={pathname}
                      />
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="5">Your customer list is empty</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default CustomersPage;
