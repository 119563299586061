import React from "react";
import { Col, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Field from "./Field";

const PropertyForm = ({ property, errors, setProperty, setErrors }) => {
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setProperty({ ...property, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  return (
    <Row>
      <Col md={12}>
        <Field
          id="name"
          label="Property name *"
          value={property.name}
          onChange={handleChange}
          error={errors.name}
        />
      </Col>
      <Col md={6} className="mb-3">
        <label>Purchasing price *</label>
        <CurrencyFormat
          className="form-control"
          value={property.marketValue}
          thousandSeparator={true}
          suffix={" CA$"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, marketValue: value });
            setErrors({ ...errors, marketValue: "" });
          }}
        />
        {errors.marketValue && (
          <p className="f-12 text-danger">{errors.marketValue}</p>
        )}
      </Col>
      <Col md={3} className="mb-3">
        <label>Tax credit *</label>
        <CurrencyFormat
          className="form-control"
          value={property.taxCredit}
          thousandSeparator={true}
          suffix={" CA$"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, taxCredit: value });
            setErrors({ ...errors, taxCredit: "" });
          }}
        />
        {errors.taxCredit && (
          <p className="f-12 text-danger">{errors.taxCredit}</p>
        )}
      </Col>
      <Col md={3} className="mb-3">
        <label>Bank rebate *</label>
        <CurrencyFormat
          className="form-control"
          value={property.bankRebate}
          thousandSeparator={true}
          suffix={" CA$"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, bankRebate: value });
            setErrors({ ...errors, bankRebate: "" });
          }}
        />
        {errors.bankRebate && (
          <p className="f-12 text-danger">{errors.bankRebate}</p>
        )}
      </Col>
      <Col md={6} className="mb-3">
        <label>Down payment *</label>
        <CurrencyFormat
          className="form-control"
          value={property.downPayment}
          thousandSeparator={true}
          suffix={" CA$"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, downPayment: value });
            setErrors({ ...errors, downPayment: "" });
          }}
        />
        {errors.downPayment && (
          <p className={"f-12 " + (errors.downPayment && "text-danger")}>
            {errors.downPayment}
          </p>
        )}
      </Col>
      <Col md={3} className="mb-3">
        <label>Incomes *</label>
        <CurrencyFormat
          className="form-control"
          value={property.income}
          thousandSeparator={true}
          suffix={" CA$"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, income: value });
            setErrors({ ...errors, income: "" });
          }}
        />
        {errors.income && <p className="f-12 text-danger">{errors.income}</p>}
      </Col>
      <Col md={3} className="mb-3">
        <label>Expenses *</label>
        <CurrencyFormat
          className="form-control"
          value={property.operatingExpense}
          thousandSeparator={true}
          suffix={" CA$"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, operatingExpense: value });
            setErrors({ ...errors, operatingExpense: "" });
          }}
        />
        {errors.operatingExpense && (
          <p className="f-12 text-danger">{errors.operatingExpense}</p>
        )}
      </Col>
      <Col md={3} className="mb-3">
        <label>Interest *</label>
        <CurrencyFormat
          className="form-control"
          value={property.interest}
          thousandSeparator={true}
          suffix={" %"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, interest: value });
            setErrors({ ...errors, interest: "" });
          }}
        />
        {errors.interest && (
          <p className="f-12 text-danger">{errors.interest}</p>
        )}
      </Col>
      <Col md={3} className="mb-3">
        <label>Amortization *</label>
        <CurrencyFormat
          className="form-control"
          value={property.amortization}
          thousandSeparator={true}
          suffix={property.amortization > 1 ? " years" : " year"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, amortization: value });
            setErrors({ ...errors, amortization: "" });
          }}
        />
        {errors.amortization && (
          <p className="f-12 text-danger">{errors.amortization}</p>
        )}
      </Col>
      <Col md={3} className="mb-3">
        <label>Growth rate *</label>
        <CurrencyFormat
          className="form-control"
          value={property.growthRate}
          thousandSeparator={true}
          suffix={" %"}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, growthRate: value });
            setErrors({ ...errors, growthRate: "" });
          }}
        />
        {errors.growthRate && (
          <p className="f-12 text-danger">{errors.growthRate}</p>
        )}
      </Col>
      <Col md={3} className="mb-3">
        <label>Multiplication factor *</label>
        <CurrencyFormat
          className="form-control"
          value={property.multiplicationFactor}
          thousandSeparator={true}
          onValueChange={(values) => {
            const { value } = values;
            setProperty({ ...property, multiplicationFactor: value });
            setErrors({ ...errors, multiplicationFactor: "" });
          }}
        />
        {errors.multiplicationFactor && (
          <p className="f-12 text-danger">{errors.multiplicationFactor}</p>
        )}
      </Col>
    </Row>
  );
};

export default PropertyForm;
